import Bowser from 'bowser';
import Session from "./Session";
import { ComparisonTags, ParameterRangeTags } from 'components/PerformanceReview/constants';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import theme from 'theme';
import dayjs from 'dayjs';

const apiUrl = process.env.REACT_APP_API_URL;

export const subMinutes = function (date, minutes) {
  return new Date(date.getTime() - minutes * 60000);
};

export const getViewportDimensions = () => {

  let width;
  let height;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth !== 'undefined') {
    width = window.innerWidth;
    height = window.innerHeight;
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (typeof document.documentElement !== 'undefined'
    && typeof document.documentElement.clientWidth !=
    'undefined' && document.documentElement.clientWidth !== 0) {
    width = document.documentElement.clientWidth;
    height = document.documentElement.clientHeight;
  }

  // older versions of IE
  else {
    width = document.getElementsByTagName('body')[0].clientWidth;
    height = document.getElementsByTagName('body')[0].clientHeight;
  }
  return { width, height };
};

export const isMobile = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const plateform = browser.getPlatform();

  if (plateform.type === 'mobile' && window?.screen && window?.screen?.width < 500) {
    return true;
  }

  return false;
};

export const isBrowserSupported = async () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  let isValidBrowser = browser.satisfies({
    windows: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    linux: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    macos: {
      'internet explorer': '>9999',
      safari: '>14.1',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    ios: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>9999',
      firefox: '>9999',
      opera: '>9999',
      edge: '>9999'
    },
    android: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>9999',
      firefox: '>9999',
      opera: '>9999',
      edge: '>9999'
    },
    'Chrome OS': {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
  });

  isValidBrowser = isValidBrowser && !(navigator.brave && await navigator.brave.isBrave());

  if (!isValidBrowser) console.error("UNSUPPORTED_BROWSER::", browser);

  if (isValidBrowser) {
    try {
      const result = await fetch(`${apiUrl}/detect-brave?gclid=3&fbclid=2&sub=marine`);
      isValidBrowser = isValidBrowser && (await result.text());
    } catch (error) { }
  }

  if (isValidBrowser) {
    return !(window?.screen && window?.screen?.width < 500)
  }

  return isValidBrowser;
};

const getUserMedia = constraints => {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      var getUserMedia = navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(
          new Error('getUserMedia is not implemented in this browser')
        );
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  } else {
    return navigator.mediaDevices.getUserMedia(constraints);
  }
};

export const checkMicrophone = () => {
  return new Promise((resolve, reject) => {
    getUserMedia({ audio: true })
      .then(function (stream) {
        resolve(true);
      })
      .catch(function (err) {
        resolve(false);
      });
  });
};

export const secondsToMMSS = (seconds) => {
  if (seconds < 0) return "";
  var MM = Math.floor(seconds / 60);
  var SS = seconds - (MM * 60);

  if (MM < 10) MM = `0${MM}`;
  if (SS < 10) SS = `0${SS}`;

  return `${MM}:${SS}`;
}

export const getClientPrefix = (_user = {}) => {
  const user = Session.getUser() || _user;
  const clientName = user?.client
    ? user?.client?.name
    : user?.name;

  return clientName ? clientName
    .split(" ")
    .map(part => part.toLowerCase())
    .join("-") : 'languify';
}

export const getAverageScore = (speechScore = 0, contentScore = 0) => {
  const {
    communication = 50, content = 50
  } = Session.getClientMetadata()?.performanceWeightage || {};

  return (
    ((speechScore * communication) + (contentScore * content)) / 100
  );
}

export const getPercentageChange = (currentScore = 0, previousScore = 0) => {
  if (previousScore === 0 && currentScore > 0) {
    return 100;
  }
  return ((currentScore - previousScore) / previousScore) * 100;
}

export function capitalizeFirstLetter(input) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function isIframe() {
  return (
    window.location !== window.parent.location ||
    window.self !== window.top ||
    !!window.frameElement
  )
}

export const copyToClipboard = (text, textRef) => {
  if (navigator.clipboard?.writeText) {
    navigator?.clipboard?.writeText(text)
      .then(() => { })
      .catch(() => { });
  } else {
    if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(textRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
      document?.execCommand('copy');
      selection.removeAllRanges();
    }
  }
}

export const getNumberWithSuffix = (number = 0) => {
  if (number === 0) return "0";

  const remainder10 = number % 10;
  const remainder100 = number % 100;

  if (remainder10 === 1 && remainder100 !== 11) {
    return `${number}st`;
  } else if (remainder10 === 2 && remainder100 !== 12) {
    return `${number}nd`;
  } else if (remainder10 === 3 && remainder100 !== 13) {
    return `${number}rd`;
  } else {
    return `${number}th`;
  }
}

export const round = (number = 0) => {
  const _number = isNaN(number) ? 0 : number;

  return Math.round(_number);
}

export const getTwoDigitNo = (number = 0) => {
  return number < 10 ? ('0' + number) : number
}

export const sortTodoList = (a, b) => {
  if (a.totalAttemptsCount !== b.totalAttemptsCount) {
    return (a.totalAttemptsCount || 0) - (b.totalAttemptsCount || 0);
  }

  if (a.expiresSoon && !b.expiresSoon) return -1;
  if (!a.expiresSoon && b.expiresSoon) return 1;

  if (a.new && !b.new) return -1;
  if (!a.new && b.new) return 1;

  return dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1;

};

export function searchStringInString(searchQuery, targetString) {
  const query = searchQuery?.toLowerCase();
  const target = targetString?.toLowerCase();
  return target.includes(query);
}

export const getComparisonTagDetails = (
  range, previousValue, newValue, indicator = false, compareIncrement = false
) => {
  const determineRangeTag = (value) =>
    (value >= range.min && value <= range.max) ? ParameterRangeTags.IDEAL : ParameterRangeTags.NON_IDEAL;

  const preRangeTag = determineRangeTag(previousValue);
  const newRangeTag = determineRangeTag(newValue);

  const arrow = (compareIncrement ? (previousValue < newValue) : (previousValue >= newValue))
    ? <TrendingUpIcon style={{ fontSize: '20px' }} />
    : <TrendingDownIcon style={{ fontSize: '20px' }} />

  const tagDetails = {
    [`${ParameterRangeTags.IDEAL}_${ParameterRangeTags.IDEAL}`]: {
      tag: ComparisonTags.OKAY, tagBgColor: theme.palette.warning['clr-100'], 
      tagColor: theme.palette.warning['clr-700'],
      arrow: indicator ? arrow : null
    },
    [`${ParameterRangeTags.NON_IDEAL}_${ParameterRangeTags.NON_IDEAL}`]: {
      tag: ComparisonTags.REQUIRES_ATTENTION, tagBgColor: theme.palette.danger['clr-100'], 
      tagColor: theme.palette.danger['clr-700'],
      arrow: indicator ? arrow : null
    },
    [`${ParameterRangeTags.IDEAL}_${ParameterRangeTags.NON_IDEAL}`]: {
      tag: ComparisonTags.REQUIRES_ATTENTION, tagBgColor: theme.palette.danger['clr-100'], 
      tagColor: theme.palette.danger['clr-700'],
      arrow: indicator ? arrow : null
    },
    [`${ParameterRangeTags.NON_IDEAL}_${ParameterRangeTags.IDEAL}`]: {
      tag: ComparisonTags.GOOD, tagBgColor: theme.palette.success['clr-100'], 
      tagColor: theme.palette.success['clr-700'],
      arrow: indicator ? <TrendingUpIcon style={{ fontSize: '20px' }} /> : null
    },
  };

  return tagDetails[`${preRangeTag}_${newRangeTag}`];
}

export const calculateBarValue = (value, range) => {
  if (value <= range) {
    return (value / range) * 33.33;
  } else {
    return 33.33 + ((value - range) / (100 - range)) * 66.66;
  }
};

export const calculateGaugePercentage = (value, minRange, maxRange) => {
  if (value <= minRange) {
    return (value / minRange) * 60;
  } else if (value > minRange && value <= maxRange) {
    return 60 + ((value - minRange) / (maxRange - minRange)) * 60;
  } else {
    return 120 + ((value - maxRange) / (180 - maxRange)) * 60;
  }
};

export const calculateTimeDifference = (startTime, endTime) => {
  const start = dayjs(startTime);
  const end = dayjs(endTime);
  const diffInSeconds = end.diff(start, 'second');

  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds % 60;

  return { minutes, seconds };
};

export const scrollToElementById = (elementId, position='center') => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  } else {
    console.error(`Element with ID "${elementId}" not found.`);
  }
}

export * from "./analytics.js";
export * from "./audio.js";
export * from "./chartUtil.js";
export * from "./cryptoUtil.js";
export * from "./date.js";
export * from "./permissions.js";
export * from "./roles.js";
export * from "./speedtest.js";
export * from "./Session.js";
export * from "./WavRecorder.js";
export * from "./canvas";