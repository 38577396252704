import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import makeStyles from "@mui/styles/makeStyles";
import { DafInterviewSteps, DocumentOptions, InterviewSteps, ReportInterviewSteps } from './constants';
import useStore from './store';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  progressBar: {
    width: '100%',
    height: '8px',
    backgroundColor: theme.palette.neutral.disabled,
    borderRadius: '4px',
    position: 'relative',
    overflow: 'hidden',
  },
  progressSection: {
    position: 'absolute',
    height: '100%',
    transition: 'width 0.3s ease-in-out',
    borderRight: `2px solid ${theme.palette.background.paper}`,
  },
  navigationArrows: {
    display: 'flex',
    marginTop: '16px',
    marginLeft : '16px'
  },
}));

const Steps = ({ stepNumber, setStepNumber, setOpenSelectDocumentOptions }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [activeStep, selectedDocument, setActiveStep] = useStore(store => [
    store.activeStep, store.selectedDocument, store.setActiveStep
  ]);
  const init = useStore(store => store.init);

  const [steps, setSteps] = useState(Object.values(InterviewSteps).filter(step => step && step._id));
  const [completedSteps, setCompletedSteps] = useState(new Set());

  // Determining Interview Steps according to Doc Type
  useEffect(() => {
    if (selectedDocument === DocumentOptions.JD._id) {
      const filteredSteps = Object.values(InterviewSteps)
        .filter(step => step && step._id)
        .filter(step => step._id !== InterviewSteps.SELECT_RESUME._id);
      setSteps(filteredSteps);
    } 
    else if (selectedDocument === DocumentOptions.OTHERS._id){                           
      setSteps(Object.values(ReportInterviewSteps).filter(step => step && step._id));                
    } 
    else if (selectedDocument === DocumentOptions.DAF._id){
      setSteps(Object.values(DafInterviewSteps).filter(step => step && step._id));
    } 
    else { 
      setSteps(Object.values(InterviewSteps).filter(step => step && step._id));
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (steps[stepNumber] && steps[stepNumber]._id) {
      setActiveStep(steps[stepNumber]._id);
    }
    
    setCompletedSteps((prevCompletedSteps) => {
      const newCompletedSteps = new Set(prevCompletedSteps);
      for (let i = 0; i <= stepNumber && steps[i]; i++) {
        if (steps[i] && steps[i]._id) {
          newCompletedSteps.add(steps[i]._id);
        }
      }
      return newCompletedSteps;
    });
  }, [stepNumber, steps]);

  const handleNext = () => {
    if (
      stepNumber < steps.length - 1 && 
      steps[stepNumber + 1] && 
      completedSteps.has(steps[stepNumber + 1]._id)
    ) {
      setStepNumber(stepNumber + 1);
    }
  };

  const handlePrevious = () => {
    if(selectedDocument === DocumentOptions.OTHERS._id){
      if(activeStep === ReportInterviewSteps.SELECT_REPORT._id){
        setOpenSelectDocumentOptions(true)
        init({});
      } else if(stepNumber > 0) {
        setStepNumber(stepNumber - 1);
      }
    }else if(selectedDocument === DocumentOptions.DAF._id){
      if(activeStep === DafInterviewSteps.DAF_UPLOAD._id){
        setOpenSelectDocumentOptions(true)
        init({});
      } else if(stepNumber > 0) {
        setStepNumber(stepNumber - 1);
      }
    }
    else if(activeStep === InterviewSteps.JOB_DETAILS._id){
      init({});
      setOpenSelectDocumentOptions(true)
    } else if(stepNumber > 0) {
      setStepNumber(stepNumber - 1);
    }
  };

  const getSectionColor = (index) => {
    // Defensive color selection
    try {
      if (steps[index] && completedSteps.has(steps[index]._id)) {
        return '#52B8CC';
      }
      return index <= stepNumber 
        ? (theme.palette.primary?.main || '#1961BF')
        : (theme.palette.neutral?.disabled || '#CCCCCC');
    } catch (error) {
      console.error('Error in getSectionColor:', error);
      return '#CCCCCC'; // Fallback color
    }
  };

  return (
    <Box className={classes.progressContainer}>
      <Box className={classes.progressBar}>
        {steps.map((step, index) => (
          <Box
            key={step._id}
            className={classes.progressSection}
            sx={{
              left: `${(index / steps.length) * 100}%`,
              width: `${100 / steps.length}%`,
              backgroundColor: getSectionColor(index),
            }}
          />
        ))}
      </Box>
      <Box className={classes.navigationArrows}>
        <IconButton
          onClick={handlePrevious}
          disabled={stepNumber === 0 && 
            (activeStep !== InterviewSteps.JOB_DETAILS._id 
              && activeStep !== ReportInterviewSteps.SELECT_REPORT._id 
              && activeStep !== DafInterviewSteps.DAF_UPLOAD._id)
            }
          sx={{
            color: stepNumber > 0 
            || activeStep === InterviewSteps.JOB_DETAILS._id 
            || activeStep === ReportInterviewSteps.SELECT_REPORT._id
            || activeStep === DafInterviewSteps.DAF_UPLOAD._id
              ? '#1961BF'
              : theme.palette.neutral.disabled,
          }}
        >
          <ArrowBack />
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={stepNumber === steps.length - 1 || !completedSteps.has(steps[stepNumber + 1]._id)}
          sx={{
            color: (stepNumber < steps.length - 1 && completedSteps.has(steps[stepNumber + 1]._id))
              ? '#1961BF'
              : theme.palette.neutral.disabled,
          }}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Steps;
