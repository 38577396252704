import React from 'react'
import makeStyles from "@mui/styles/makeStyles";
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { InterviewRounds } from './constants';
import { nextStepAudio } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    marginTop: theme.spacing(13),
    position: 'relative',
    animation: '$slideInFromRight 0.6s ease-out',
  },
  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(15px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  childContainer : {
    display : 'flex',
    flexDirection : 'column',
    gap : '30px',
    width : '100%',
    maxHeight : '420px',
    overflow : 'auto',
    padding : theme.spacing(0,6)
  },
  boxContainer : {
    padding : theme.spacing(3),
    width : '100%',
    border : `1px solid ${theme.palette.neutral['clr-300']}`,
    borderRadius : theme.spacing(1),
    cursor : 'pointer',
    minHeight : '166px',
    position : 'relative',
    '&:hover' : {
      border :  `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 2px 8px 0px #D2D2D299`
    }
  },
  serial : {
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    borderRadius : '50%',
    backgroundColor : theme.palette.neutral['clr-100'],
    width : '25px',
    height : '20px',
    position : 'relative',
  },
  tag : {
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    padding : theme.spacing(0.5,1.5),
    backgroundColor : theme.palette.neutral['clr-50'],
    borderRadius : theme.spacing(1)
  },
  arrow : {
    position : 'absolute',
    top : '20px'
  },
  tooltip: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    color: theme.palette.neutral['clr-900'], 
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', 
    borderRadius: '4px',
    padding: theme.spacing(1,2),
    ...theme.typography['body02-bold'],
    maxWidth:'250px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    border:`1px solid ${theme.palette.neutral['clr-500']}`
  },
  arrowTip: {
    color: theme.palette.shades['clr-white-900'],
    '&::before': {
      border:`1px solid ${theme.palette.neutral['clr-500']}`,  
      backgroundColor: theme.palette.shades['clr-white-900'],   
      boxSizing: 'border-box', 
    },
  },
}))

const InterviewRound = ({stepNumber , next , setInterviewRound}) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleInterviewRound = (id) => {   
    nextStepAudio()
    
    setInterviewRound(id)
    next(stepNumber + 1);
  }

  return (
    <Box className={classes.container}>
      <Typography 
        variant='h5-medium' 
        color='neutral.clr-600' 
        sx={{marginBottom : '12px' , padding : theme.spacing(0,6)}}
      >
        Which interview round you are practicing for?
      </Typography>
      <Box className={classes.childContainer}>
      {InterviewRounds.map((item, index) => (
        <Box key={index} className={classes.boxContainer} onClick={() => handleInterviewRound(item._id)}>
          <img 
            src='https://assets.languify.in/images/interviewRound.svg' 
            alt='crc'
            style={{position : 'absolute' , bottom : 0 , right : 0}}
          />
          <Box display="flex" gap="10px">
            <Box className={classes.serial}>
              <Typography variant="body02-bold" color="neutral.clr-900">
                {index + 1}
              </Typography>
              {index !== InterviewRounds.length - 1 && (
                <img
                  className={classes.arrow}
                  src="https://assets.languify.in/images/dottedArrow.svg"
                  alt="Dotted Arrow"
                />
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box display="flex" flexDirection="column">
                <Typography variant="h6-medium" color="neutral.clr-800">
                  {item.round}
                </Typography>
                <Typography
                  variant="body02-bold"
                  color="neutral.clr-500"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Interviewer’s expectation &nbsp;
                   <Tooltip 
                    title={item.tooltip}
                    arrow 
                    placement="top"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: false, 
                        },
                      ],
                    }}
                    classes={{ tooltip: classes.tooltip, arrow: classes.arrowTip }}
                  >
                  <img
                    src="https://assets.languify.in/images/disclaimer.svg"
                    alt="Disclaimer Icon"
                  />
                  </Tooltip>
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap="8px">
                <Typography variant="body02-medium" color="neutral.clr-900">
                  Questions based on:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap="4px">
                  {item.tags.map((tag, tagIndex) => (
                    <Box key={tagIndex} className={classes.tag}>
                      <Typography
                        variant="body02-semiBold"
                        color="neutral.clr-800"
                      >
                        {tag}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}     
      </Box>
    </Box>
  )
}

export default InterviewRound