import React , {useState , useEffect} from 'react'
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { SimpleSelect } from 'components/CustomSelectFields';
import TextField, { TextArea } from 'components/CustomFields';
import * as Yup from 'yup';
import makeStyles from "@mui/styles/makeStyles";

import CustomButton from 'components/CustomButton';
import DetailsForm from './DafDetailsForm';
import useStore from './store';
import { dafEducationFields, dafEmploymentFields, DafInterviewSteps } from './constants';
import { state } from './states';
import { useRef } from 'react';
import { nextStepAudio } from 'utils';

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: 'flex',
    width: '378px',
    justifyContent: 'center',
    height: '100%',
    marginTop : theme.spacing(13),
    marginBottom : theme.spacing(13),
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    '& .MuiTextField-root': {
      scrollMarginTop: '100px',
    },
  },
  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(15px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  container : {
    display:'flex',
    width:'336px',
    flexDirection:'column',
    gap:theme.spacing(6),
    animation: '$slideInFromRight 0.6s ease-out',
  },
  bottomButton : {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height:'76px',
    position:'absolute',
    width:'100%',
    boxShadow: `0px 0px 8px 0px #00000040`,
    bottom : '0px',
    right:'0px',
    backgroundColor:theme.palette.shades['clr-white-900']
  },
  detailsBox:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(2)
  },
  tooltip: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    color: theme.palette.neutral['clr-900'], 
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', 
    borderRadius: '4px',
    padding: theme.spacing(1,2),
    ...theme.typography['body02-bold'],
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    border:`1px solid ${theme.palette.neutral['clr-500']}`
  },
  arrow: {
    color: theme.palette.shades['clr-white-900'],
    '&::before': {
      border:`1px solid ${theme.palette.neutral['clr-500']}`,  
      backgroundColor: theme.palette.shades['clr-white-900'],   
      boxSizing: 'border-box', 
    },
  },
}))

const items = [
  {
    id: 'postGraduation',
    alt: 'Add post graduation',
    label: 'Add Post graduation',
  },
  {
    id: 'graduation',
    alt: 'Add graduation',
    label: 'Add Graduation',
  },
  {
    id: 'seniorSecondary',
    alt: 'Add senior secondary (XII)',
    label: 'Add Senior secondary (XII)',
  },
  {
    id: 'secondary',
    alt: 'Add secondary (X)',
    label: 'Add Secondary (X)',
  },
  {
    id: "diploma",
    alt: 'Add diploma',
    label: 'Add Diploma',
  },
  {
    id: "phd",
    alt: 'Add Phd',
    label: 'Add Phd',
  },
];

const DetailsSection = ({
  title,
  isExtracted,
  onAddNewDetails,
  extractedMessage,
  items,
  addIconSrc="https://assets.languify.in/images/add-icon.svg",
  tickIconSrc="https://assets.languify.in/images/check-contained.svg",
  isEmploymentDetails,
  employmentData,
  setEmploymentData,
  educationData,
  setEducationData
}) => {
  const classes = useStyles();

  const [selectedForm, setSelectedForm] = useState(null);

  const handleOpenDialog = (data, isEdit, existingData = null, index = null) => {
    if (isEdit) {
      setSelectedForm({ ...data, prefill: existingData, index });
    } else {
      setSelectedForm(data);
    }
  };

  const handleCloseDialog = () => {
    setSelectedForm(null);
  };

  const handleSubmitDetails = (values) => {
    if (isEmploymentDetails) {
      setEmploymentData((prevData) => {
        if (selectedForm.index !== undefined) {
          return prevData.map((item, index) =>
            index === selectedForm.index ? values : item
          );
        } else {
          return [...prevData, values];
        }
      });
    } else {
      setEducationData((prevData) => ({
        ...prevData,
        [selectedForm.id]: values,
      }));
    }
    handleCloseDialog();
  };
  

  const handleDeleteDetails = (idOrIndex) => {
    if (isEmploymentDetails) {
      setEmploymentData((prevData) => prevData.filter((_, i) => i !== idOrIndex));
    } else {
      setEducationData((prevData) => {
        const newData = { ...prevData };
        delete newData[idOrIndex];
        return newData;
      });
    }
  };
  
  return isExtracted ? (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        paddingBottom="8px"
        borderBottom="1px solid #8692A3"
      >
        <Typography variant="body01-bold">{title}</Typography>
        <Tooltip 
          title={`This will clear your exisiting details`}
          arrow 
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: 'flip',
                enabled: false, 
              },
            ],
          }}
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
          <Typography
            onClick={onAddNewDetails}
            variant="body01-link"
            color="primary.clr-300"
            sx={{ cursor: "pointer" }}
          >
            Add details manually
          </Typography>
        </Tooltip>
      </Box>
      <Box display="flex" gap="4px" mt={1}>
        <img src={tickIconSrc} alt="tick" />
        <Typography variant="body01-bold" color="neutral.clr-600">
          {extractedMessage}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box
        display="flex"
        justifyContent="space-between"
        paddingBottom="8px"
        borderBottom="1px solid #8692A3"
      >
        <Typography variant="body01-bold">{title}</Typography>
      </Box>

      {isEmploymentDetails && (
        <Box
          display="flex"
          gap="2px"
          sx={{ cursor: "pointer" }}
          onClick={() => handleOpenDialog(items[0], false)}
        >
          <img src={addIconSrc} alt={items[0]?.alt} />
          <Typography variant="body01-bold" color="primary.clr-300">
            {items[0]?.label}
          </Typography>
        </Box>
      )}

      {isEmploymentDetails && employmentData.map((employment, index) => (
        <Box key={index} display="flex" flexDirection="column" gap="8px">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body01-bold" color="neutral.clr-700">
              {employment.jobTitle} {employment.companyName}
            </Typography>
            <Box display="flex" gap="16px">
              {/* Edit Icon */}
              <img
                src="https://assets.languify.in/images/editResume.svg"
                alt="edit"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleOpenDialog(items[0], true, employment, index)
                }
              />
              {/* Delete Icon */}
              <img
                src="https://assets.languify.in/images/trashResume.svg"
                alt="trash"
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteDetails(index)}
              />
            </Box>
          </Box>
          <Typography variant="body01-semiBold" color="neutral.clr-600">
          {employment.location} | {employment.employmentType}
          </Typography>
          <Typography variant="body01-semiBold" color="neutral.clr-600">
            {new Date(employment.startDate).toLocaleString('en-US', { month: 'long', year: 'numeric' })} |  &nbsp;
            {new Date(employment.endDate).getMonth() === new Date().getMonth() && 
            new Date(employment.endDate).getFullYear() === new Date().getFullYear()
              ? 'Present'
              : new Date(employment.endDate).toLocaleString('en-US', { month: 'long', year: 'numeric' })}
          </Typography>

          {index < employmentData.length - 1 && <Divider sx={{ borderColor: "#8692A3" }} />}
        </Box>
      ))}

      {!isEmploymentDetails &&
        items.map((item, index) => (
          <React.Fragment key={item.id}>
            {educationData[item.id] ? (
              <Box display="flex" flexDirection="column" gap="8px">
                <Box display="flex" gap="16px">
                  <Typography variant="body01-bold" color="neutral.clr-700">
                    {item.id === 'seniorSecondary' || item.id === 'secondary' || item.id === 'diploma' || item.id === 'phd'
                      ? item.label.replace(/^Add /, '')
                      : `${educationData[item.id].degree} : ${educationData[item.id].stream}`}
                  </Typography>
                  <Box display="flex" gap="16px">
                    <img
                      src="https://assets.languify.in/images/editResume.svg"
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOpenDialog(item, true, educationData[item.id])
                      }
                    />
                    <img
                      src="https://assets.languify.in/images/trashResume.svg"
                      alt="trash"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteDetails(item.id)}
                    />
                  </Box>
                </Box>
                <Typography variant="body01-semiBold" color="neutral.clr-600">
                   {item.id === 'seniorSecondary' || item.id === 'secondary'
                     ?  educationData[item.id].board
                     :  educationData[item.id].collegeName
                    }
                </Typography>
                {item.id !== 'seniorSecondary' && item.id !== 'secondary' && (
                  <Typography variant="body01-semiBold" color="neutral.clr-600">
                    {educationData[item.id].startYear} - {educationData[item.id].endYear} |{' '}
                    {educationData[item.id].courseType === 'full_time'
                      ? 'Full time'
                      : educationData[item.id].courseType === 'part_time'
                      ? 'Part time'
                      : 'Distance learning'}
                  </Typography>
                )}
                {(item.id === 'seniorSecondary' || item.id === 'secondary') && (
                  <Typography variant="body01-semiBold" color="neutral.clr-600">
                    {educationData[item.id].passingYear}
                  </Typography>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                gap="2px"
                sx={{ cursor: "pointer" }}
                onClick={() => handleOpenDialog(item, false)}
              >
                <img src={addIconSrc} alt={item.alt} />
                <Typography variant="body01-bold" color="primary.clr-300">
                  {item.label}
                </Typography>
              </Box>
            )}
            {index < items.length - 1 && <Divider sx={{ borderColor: "#8692A3" }} />}
          </React.Fragment>
        ))}

      {selectedForm !== null && (
        <DetailsForm
          open={selectedForm !== null}
          onClose={handleCloseDialog}
          formName={selectedForm.label}
          selectedIndex={selectedForm.id}
          fields={isEmploymentDetails ? dafEmploymentFields : dafEducationFields[selectedForm.id]}
          onSubmit={handleSubmitDetails}
          initialData={selectedForm.prefill || {}} 
          buttonText="Save Details"
        />
      )}
    </Box>
  );
};


const DafForm = ({ stepNumber , setStepNumber }) => {
  const classes = useStyles();
  const formRef = useRef(null);

  const [districtOptions, setDistrictOptions] = useState([]);
  const [isEducationExtracted, setIsEducationExtracted] = useState(false);
  const [isExperienceExtracted, setIsExperienceExtracted] = useState(false);
  const [employmentData, setEmploymentData] = useState([]);
  const [educationData, setEducationData] = useState({});

  const currentYear = new Date().getFullYear();

  const dafExtractedData = useStore((store) => store.dafExtractedData);
  const setDafExtractedData = useStore((store) => store.setDafExtractedData);
  
  const normalizedStateId = dafExtractedData?.state
    ? dafExtractedData.state.toLowerCase().replace(/\s+/g, '_')
    : '';
  const selectedState = state.find((s) => s._id === normalizedStateId);

  const normalizedDistrictId =
    dafExtractedData?.district && selectedState
      ? dafExtractedData.district.toLowerCase().replace(/\s+/g, '_')
      : '';
  const selectedDistrict =
    selectedState?.districts.find((d) => d._id === normalizedDistrictId) || null;

  const trimValue = (value) => {
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ' && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  const scrollToError = (errors) => {
    const firstErrorKey = Object.keys(errors)[0];
    if (!firstErrorKey) return;

    const errorElement = 
      document.querySelector(`[name="${firstErrorKey}"]`) || 
      document.getElementById(firstErrorKey);

    if (errorElement) {
      const container = document.querySelector('.parentContainer');
      const headerOffset = 100; 

      const elementPosition = errorElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      if (container) {
        container.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      } else {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }

      setTimeout(() => {
        errorElement.focus();
      }, 500);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .transform(trimValue)
      .max(50, 'Must be 50 characters or less')
      .required('Please enter your name!')
      .test('no-leading-space', 'Cannot start with spaces', value => 
        value ? value.trim() === value : true
      ),
    state: Yup.object().required('Please choose your state!'),
    district: Yup.object().required('Please choose your district!'),
    stream: Yup.string()
      .transform(trimValue)
      .required('Please enter stream!')
      .test('no-leading-space', 'Cannot start with spaces', value => 
        value ? value.trim() === value : true
      ),
    optionalSubject: Yup.string()
      .transform(trimValue)
      .required('Please enter optional subject!')
      .test('no-leading-space', 'Cannot start with spaces', value => 
        value ? value.trim() === value : true
      ),
    attempts: Yup.number().required('Please add your attempts between 0 to 30!'),
    achievements: Yup.string()
      .transform(trimValue)
      .required('Please add your achievements!')
      .test('no-leading-space', 'Cannot start with spaces', value => 
        value ? value.trim() === value : true
      ),
    interests: Yup.string()
      .transform(trimValue)
      .required('Please add your hobbies!')
      .test('no-leading-space', 'Cannot start with spaces', value => 
        value ? value.trim() === value : true
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: dafExtractedData.name || '',
      state: selectedState || '',
      district: selectedDistrict || '',
      stream: dafExtractedData.stream || '',
      optionalSubject: dafExtractedData.optionalSubjects || '',
      attempts: dafExtractedData.attempts || 0,
      achievements: dafExtractedData.achievements || '',
      interests: dafExtractedData.interests || '',
    },
    validationSchema,
    validateOnChange: false, 
    validateOnBlur: false,
    onSubmit: async (values, { setTouched, setSubmitting }) => {
      try {
        setSubmitting(true);

        nextStepAudio();

        const touchedFields = Object.keys(values).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});
        setTouched(touchedFields);
        const errors = await formik.validateForm(values);

        if (Object.keys(errors).length > 0) {
          console.log('Validation errors:', errors); 
          scrollToError(errors);
          return;
        }

        setDafExtractedData({
          ...dafExtractedData,
          name: values.name,
          state: values.state.name,
          district: values.district.name,
          stream: values.stream,
          optionalSubjects: values.optionalSubject,
          attempts: values.attempts,
          achievements: values.achievements,
          interests: values.interests,
          education: Object.keys(educationData).length === 1 && educationData.summary
            ? { summary: educationData.summary }
            : Object.fromEntries(
                Object.entries(educationData).filter(([key]) => key !== "summary")
              ),
          experience: dafExtractedData.experience?.summary
            ? employmentData.length > 0
              ? { data: employmentData }
              : { summary: dafExtractedData.experience.summary } 
            : employmentData.length > 0
            ? { data: employmentData } 
            : {},
        });    
        setStepNumber(stepNumber + 1);
      } catch (error) {
        console.error('Submission error:', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSubmit = async () => {
    try {
      const touchedFields = Object.keys(formik.values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      formik.setTouched(touchedFields);

      const errors = await formik.validateForm();

      if (Object.keys(errors).length > 0) {
        console.log('Validation errors on submit:', errors); 
        scrollToError(errors);
        return;
      }

      await formik.submitForm();
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  useEffect(() => {
    if (selectedState) {
      setDistrictOptions(selectedState.districts || []);
    }
  }, [selectedState]);

  useEffect(() => {

    if (dafExtractedData?.education) {
      setEducationData(dafExtractedData.education);
    }

    if (dafExtractedData?.experience?.data) {
      setEmploymentData(dafExtractedData?.experience?.data);
    }

    if (dafExtractedData?.education?.summary) {
      setIsEducationExtracted(true);
    }
    if (dafExtractedData?.experience?.summary) {
      setIsExperienceExtracted(true);
    }

  }, [dafExtractedData]);

  const handleExperienceDetails = () => {
    setIsExperienceExtracted(false);
  };

  const handleEducationDetails = () => {
    setIsEducationExtracted(false);
  };

  return (
    <Box  className={`${classes.parentContainer} parentContainer`}>
      <Box className={classes.container}>
        <Box display='flex' flexDirection='column' gap='4px'>
          <Box display="flex">
            <Typography variant="h5-medium" color="neutral.clr-900">
              Detailed Application Form
            </Typography>
            &nbsp;
            <Typography variant="h5-medium" color="neutral.clr-600">
              (DAF)
            </Typography>
          </Box>
          <Typography variant='body02-bold' color='neutral.clr-600'>
            Fill all the details for personalized interview experience
          </Typography>
        </Box>
        <form id="dafForm" ref={formRef}>
          <Box display="flex" flexDirection="column" gap="24px" marginBottom='20px'>
            <TextField
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              label="Full Name"
              placeholder="What’s your full name"
              required={true}
              helperText={formik.touched.name && formik.errors.name}
              onKeyDown={handleKeyDown}
            />

            <SimpleSelect
              id="state"
              name="state"
              options={state.map((s) => ({ _id: s._id, name: s.name }))}
              onChange={(event) => {
                const selectedState = state.find((s) => s._id === event.target.value);
                formik.setFieldValue('state', selectedState);
                setDistrictOptions(selectedState ? selectedState.districts : []);
                formik.setFieldValue('district', ''); 
              }}
              placeholder='Choose an option'
              onBlur={formik.handleBlur}
              value={formik.values.state}  
              error={formik.touched.state && Boolean(formik.errors.state)}
              label="State"
              required={true}
              sx={{ width: '330px' }}
              helperText={formik.touched.state && formik.errors.state}
              onKeyDown={handleKeyDown}
            />

            <SimpleSelect
              id="district"
              name="district"
              options={districtOptions.map((d) => ({ _id: d._id, name: d.name }))}
              onChange={(event) => {
                const selectedDistrict = districtOptions.find((d) => d._id === event.target.value)
                formik.setFieldValue('district', selectedDistrict)
              }}
              placeholder='Choose an option'
              onBlur={formik.handleBlur}
              value={formik.values.district}  
              error={formik.touched.district && Boolean(formik.errors.district)}
              label="District"
              required={true}
              sx={{ width: '330px' }}
              helperText={formik.touched.district && formik.errors.district}
              onKeyDown={handleKeyDown}
            />

            <TextField
              id="stream"
              name="stream"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stream}
              error={formik.touched.stream && Boolean(formik.errors.stream)}
              label="Stream at graduation level"
              required={true}
              placeholder="eg : Engineering"
              helperText={formik.touched.stream && formik.errors.stream}
              onKeyDown={handleKeyDown}
            />
            <TextField
              id="optionalSubject"
              name="optionalSubject"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.optionalSubject}
              error={formik.touched.optionalSubject && Boolean(formik.errors.optionalSubject)}
              label="Optional Subject"
              placeholder="Type subject name"
              required={true}
              helperText={formik.touched.optionalSubject && formik.errors.optionalSubject}
              onKeyDown={handleKeyDown}
            />

            <TextField
              id="attempts"
              name="attempts"
              type="number"
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0 && value <= 30) {
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.attempts}
              error={formik.touched.attempts && Boolean(formik.errors.attempts)}
              label="Attempts"
              placeholder="0"
              required={true}
              supportingLabel={`(Including this year: ${currentYear})`}
              helperText={formik.touched.attempts && formik.errors.attempts}
              onKeyDown={handleKeyDown}
            />


            <DetailsSection
              title="Educational Details"
              isExtracted={isEducationExtracted}
              onAddNewDetails={handleEducationDetails}
              extractedMessage="We have collected your educational details from DAF."
              items={items}
              educationData={educationData}
              setEducationData={setEducationData}
            />

            <DetailsSection
              title="Employment Details"
              isExtracted={isExperienceExtracted}
              onAddNewDetails={handleExperienceDetails}
              extractedMessage="We have collected your employment details from DAF."
              items={[{ id: 1, label: 'Add new', alt: 'Add employment details' }]}
              isEmploymentDetails={true}
              employmentData={employmentData}
              setEmploymentData={setEmploymentData}
            />

            <TextArea
              id="achievements"
              name="achievements"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.achievements}
              error={formik.touched.achievements && Boolean(formik.errors.achievements)}
              label="Achievements"
              placeholder="Mention your prizes, medals and scholarships at the university, state or national level."
              rows={4}
              required={true}
              helperText={formik.touched.achievements && formik.errors.achievements}
              onKeyDown={handleKeyDown}
            />

            <TextArea
              id="interests"
              name="interests"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.interests}
              error={formik.touched.interests && Boolean(formik.errors.interests)}
              label="Your Interests/Hobbies"
              placeholder="Mention your interests here..."
              rows={4}
              required={true}
              helperText={formik.touched.interests && formik.errors.interests}
              onKeyDown={handleKeyDown}
            />
          </Box>
        </form>
      </Box>
      <Box className={classes.bottomButton}>
        <CustomButton
          sx={{ width: '336px' }}
          size="medium"
          onClick={handleSubmit}
          disabled={formik.isSubmitting}
        >
          Next
        </CustomButton>
      </Box>
    </Box>
  );
};

export default DafForm;

