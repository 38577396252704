import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from '@mui/material';

import { WhiteBox } from 'components/AppShell';
import CustomButton, { LinkButton } from 'components/CustomButton';
import { DocContent, InterviewRounds } from './constant';
import InfoIconWithTooltip, { BootstrapTooltip } from 'components/InfoIconWithTooltip';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '50%',
    padding: theme.spacing(4),
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  interviewRoundSec: {
    backgroundColor: '#FFE6E3',
  },
  interviewTypeSec: {
    borderRadius: '8px',
    backgroundColor: '#DCFCE7',
    position: 'relative'
  },
  documents: {
    position: 'absolute',
    bottom: -5,
    right: 0,
  },
  creditsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-300'],
    borderRadius: '8px',
    padding: theme.spacing(1, 3),
    alignItems: 'center',
    height: '48px'
  },
  fadeContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  fadeContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out',
  },
  visible: {
    opacity: 1,
  }
}));

const Introduction = ({ creditsLeft = 0, loading = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [currentDocIndex, setCurrentDocIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDocIndex((prevIndex) => (prevIndex + 1) % DocContent.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      <WhiteBox>
        <Box width='100%' display='flex' gap={4}>
          <Box className={`${classes.card} ${classes.interviewRoundSec}`}>
            <Box style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant='body01-bold' color='danger.clr-700'>
                You're in a doubt?
              </Typography>
              <Typography variant='h5-medium' color='neutral.clr-600'>
                Not sure what questions an interviewer might ask you in...
              </Typography>
              <div className={classes.fadeContainer}>
                {InterviewRounds.map((round, index) => (
                  <div
                    key={index}
                    className={`${classes.fadeContent} ${index === currentDocIndex ? classes.visible : ''}`}
                  >
                    <Typography variant='h4-medium'>
                      {round}
                    </Typography>
                  </div>
                ))}
              </div>
            </Box>
            <Box>
              <img src='https://assets.languify.in/images/3bulbs.png' alt='lightning' />
            </Box>
          </Box>
          <Box className={`${classes.card} ${classes.interviewTypeSec}`}>
            <Box
              style={{
                width: '65%', display: 'flex', flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Typography variant='body01-bold' color='success.clr-700'>
                  We’ve got you covered!
                </Typography>
                <Typography variant='h5-medium' color='neutral.clr-400'>
                  Create interview based on:
                </Typography>
                <div className={classes.fadeContainer}>
                  {DocContent.map((doc, index) => (
                    <div
                      key={index}
                      className={`${classes.fadeContent} ${index === currentDocIndex ? classes.visible : ''}`}
                    >
                      <Typography variant='h4-medium'>
                        {doc.title}
                      </Typography><br/>
                      <Typography variant='body01-bold'>
                        {doc.description}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Box>
              <BootstrapTooltip
                title={
                  <Typography variant='body02-semiBold'>
                    Practice what an interviewer might ask you before the interview. <b>Let’s do it!</b>
                  </Typography>
                }
                placement='top'
              >
                <Box>
                  <CustomButton
                    sx={{ width: 'fit-content' }}
                    size='medium'
                    onClick={() => navigate('create')}
                    disabled={creditsLeft <= 0}
                  >
                    Create new Interview
                  </CustomButton>
                </Box>
              </BootstrapTooltip>
            </Box>
            <Box className={classes.documents}>
              <img src={DocContent[currentDocIndex].img} alt='documents' />
            </Box>
          </Box>
        </Box>
        {
          loading && isNumber(creditsLeft) ? null :
            <Box className={classes.creditsBox}>
              <Box display={'flex'}>
                {
                  creditsLeft ?
                    <>
                      <Typography variant='body01-bold'>
                        Hey! You have enough credits for practicing new Interview.
                      </Typography>&nbsp;
                      <LinkButton style={{ padding: '0px' }} onClick={() => navigate('create')}>
                        Let’s begin!
                      </LinkButton>
                    </> :
                    <Typography variant='body01-bold' color='danger.clr-700'>
                      Hey! Your credits are exhausted!
                    </Typography>
                }
              </Box>
              <Box display='flex' alignItems='center' gap='4px'>
                <Typography variant='body01-bold' color={creditsLeft ? 'neutral.main' : 'danger.clr-700'}>
                  Available Credits: {creditsLeft}
                </Typography>
                <InfoIconWithTooltip
                  placement='top'
                  title={
                    creditsLeft 
                      ? 'Use these credits for creating new Interview.' 
                      : 'Your don’t have credits for creating new Interview.'
                  } 
                />
              </Box>
            </Box>
        }

      </WhiteBox>
    </Box>
  );
}

export default Introduction;