export const RETAKES_AVAILABLE = 1;

export const AssessmentStates = {
    LOADED: "LOADED",
    STARTING: "STARTING",
    RUNNING: "RUNNING",
    FINISHING: "FINISHING",
    COMPLETED: "COMPLETED",
    WAITING_FOR_RESULT: "WAITING_FOR_RESULT",
    SHOWING_TUTORIAL: "SHOWING_TUTORIAL",
    FETCHING_QUESTIONS: "FETCHING_QUESTIONS",
    FULLSCREEN_PERMISSION: "FULLSCREEN_PERMISSION"
};

export const TutorialTips = [
    {
        id: "first-tip-assessment",
        message:
            'To begin your answering simply click "Record Answer"',
    },
    {
        id: "interview-questions",
        message:
            "See the progress of your assessment and how many questions you have left here",
    },
    {
        id: "last-tip-assessment",
        message:
            "End the session after you finish answering",
    },
];

export const QuestionStates = {
    WAITING: "WAITING",
    RECORDING: "RECORDING",
    RECORDING_STOPPED: "RECORDING_STOPPED",
    SUBMITTED: "SUBMITTED",
    SHOWING_TUTORIAL: "SHOWING_TUTORIAL",
};

export const interviewerStateTags = {
  SPEAKING: (speaker='Amy') => `${speaker} is Speaking`,
  WAITING: (speaker='Amy') => `${speaker} is waiting for your response`,
  RECORDING: (speaker='Amy') => `${speaker} is Listening`,
  NEXT: (speaker='Amy') => {
    const Tags = [
      `${speaker='Amy'} is coming up with the next question`,
      `${speaker='Amy'} is thinking about the next question`,
      `${speaker='Amy'} is coming up with the following question`,
      `${speaker='Amy'} is pondering the next question`,
      `${speaker='Amy'} is preparing the next question`,
      `${speaker='Amy'} is crafting the next question`,
      `${speaker='Amy'} is brainstorming the next question`,
      `${speaker='Amy'} is getting ready with the next question`,
      `${speaker='Amy'} is developing the next question`,
      `${speaker='Amy'} is working on the next question`,
    ]
    
    return Tags[Math.floor(Math.random() * Tags.length)];
  },
}

export const Interviewers = {
  Niamh: {
    name: 'Emma',
    engine: 'neural',
    language: 'en-IE',
    voiceId: 'Niamh',
    image: "https://assets.languify.in/images/emma.png" 
  },
  Amy: {
    name: 'Miley',
    engine: 'neural',
    language: 'en-GB',
    voiceId: 'Amy',
    image: "https://assets.languify.in/images/miley.png" 
  },
  Brian: {
    name: 'David',
    engine: 'neural',
    language: 'en-GB',
    voiceId: 'Brian',
    image: "https://assets.languify.in/images/david.png" 
  }
}