import Client from "./_client";

export const getTags = async (filters) => {
  let url = "/tag?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get tags. Please try again."
    );
  }
  return result?.data;
}

export const getCompanyJobRoleMap = async () => {
  let url = "tag/companyJobRoleMap";


  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get company. Please try again."
    );
  }
  return result?.data;
}
