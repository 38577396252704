import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from 'components/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const PrimaryActionVariants = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};

function ConfirmationDialog({
  open,
  onSecondaryAction,
  onPrimaryAction,
  title,
  message,
  primaryAction,
  secondaryAction,
  primaryActionType= 'contained',
  secondaryActionType= 'outlined',
  onClose,
  buttonSize,
  mobileView=false,
  ...dialogProps
}) {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={(typeof onClose === 'function') ? onClose : onSecondaryAction}
      aria-labelledby="responsive-confirmation-dialog"
      maxWidth={'sm'}
      fullWidth={true}
      {...dialogProps}
    >
      <DialogTitle id="responsive-dialog-title" sx={{ m: 0, p: theme.spacing(6,5,0,5) }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5-medium'>
            {title}
          </Typography>
          <IconButton onClick={(typeof onClose === 'function') ? onClose : onSecondaryAction}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Divider/>
      </DialogTitle>
      <DialogContent  sx={ mobileView ? {mt: 4, p: theme.spacing(5)} : {minWidth: 380, mt: 4, p: theme.spacing(5) }}>
        <Typography variant='h6-regular'>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions sx={ mobileView ? { m: 0, p: theme.spacing(6,5) } : { minWidth: 380, m: 0, p: theme.spacing(6,5) }}>
        <CustomButton
          onClick={onSecondaryAction}
          variant={secondaryActionType}
          sx={{width: '50%'}}
          autoFocus
          size={buttonSize}
        >
          {secondaryAction}
        </CustomButton>
        <CustomButton
          onClick={onPrimaryAction}
          variant={primaryActionType}
          sx={{width: '50%'}}
          autoFocus
          size={buttonSize}
        >
          {primaryAction}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;