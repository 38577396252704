import React, { useState, useEffect } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { AutoCompleteSelect, SimpleSelect } from 'components/CustomSelectFields';
import { DocumentOptions } from './constants';
import { useServices } from 'contexts';
import { getCompanyJobRoleMap, getTags } from 'services/tagService';
import { getDomains } from 'services';
import { nextStepAudio } from 'utils';
import TextField, { TextArea } from 'components/CustomFields';
import CustomButton from 'components/CustomButton';
import useStore from './store';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    marginTop: theme.spacing(13),
    padding: theme.spacing(0, 6),
  },
  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(15px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  jobDetailsBotContents: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  noJobRole: {
    cursor: 'pointer'
  },
  bottomButton : {
    display : 'flex',
    flexDirection : 'column',
    gap : theme.spacing(2),
    position : 'absolute',
    bottom : '10px',
    width : '336px'
  },
  skeleton: {
    borderRadius: theme.spacing(3),
    width: '100%', height: '300px',
  },
  fieldContainer : {
    display : 'flex', 
    flexDirection : 'column', 
    gap : '8px',
    animation: '$slideInFromRight 0.6s ease-out',
  },
  loader: {
    position: "relative",
    width: "64px",
    height: "64px",
    background: "#fff",
    borderRadius: "50%",
    overflow: "hidden",
    "&:after": {
      content: '""',
      position: "absolute",
      inset: "8px",
      margin: "auto",
      background: "#fff",
      borderRadius: "50%",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      inset: "0px",
      margin: "auto",
      background: `#52B8CC`,
      animation: "$crlMugLoader 2s linear infinite alternate",
    },
  },
  "@keyframes crlMugLoader": {
    "0%, 10%": { transform: "translateY(64px)" },
    "90%, 100%": { transform: "translateY(0px)" },
  },
}));

const resumeValidationSchema = Yup.object().shape({
  jobRole: Yup.string().when('openJobRoleField', {
    is: true,
    then: () => Yup.string().required('Job role is required'),
    otherwise: () => Yup.string()
  }),
  domain: Yup.mixed().nullable(),
  jobRole: Yup.mixed().when('openJobRoleField', {
    is: false,
    then: () => Yup.mixed().required('Please select a job role'),
    otherwise: () => Yup.mixed().nullable()
  })
});

const jobDetailsValidationSchema = Yup.object().shape({
  companyName: Yup.string().when('manualCompanyName', {
    is: true,
    then: () => Yup.string().required('Company name is required'),
    otherwise: () => Yup.string()
  }),
  jobRole: Yup.string().when('manualCompanyName', {
    is: true,
    then: () => Yup.string().required('Job role is required'),
    otherwise: () => Yup.string()
  }),
  domain: Yup.mixed().nullable(),
  jobDescription: Yup.string().when('manualCompanyName', {
    is: true,
    then: () => Yup.string().required('Job description is required'),
    otherwise: () => Yup.string()
  }),
  jobRole: Yup.mixed().when('manualCompanyName', {
    is: false,
    then: () => Yup.mixed().required('Please select a job role'),
    otherwise: () => Yup.mixed().nullable()
  }),
  companyName: Yup.mixed().when('manualCompanyName', {
    is: false,
    then: () => Yup.mixed().required('Please select a company'),
    otherwise: () => Yup.mixed().nullable()
  }),
});

const SelectJobDetails = ({
  stepNumber,
  next,
  manualCompanyName,
  setManualCompanyName,
  clientMock,
  setIsDataChanged
}) => {
  const classes = useStyles();
  const service = useServices();

  const [
    selectedDocument, jobDescription, companyName,
    jobRole, domain, jobRoles, setJobDescription,
    setCompanyName, setJobRole, setDomain, setJobRoles
  ] = useStore(store => [
    store.selectedDocument, store.jobDescription,
    store.companyName, store.jobRole, store.domain,
    store.jobRoles, store.setJobDescription,
    store.setCompanyName, store.setJobRole,
    store.setDomain, store.setJobRoles
  ]);

  const [openJobRoleField, setOpenJobRoleField] = useState(false);
  const [mappedJobRoles, setMappedJobRoles] = useState([]);
  const [companies , setCompanies] = useState([])
  const [jobRolesForResume, setJobRolesForResume] = useState([]);
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInterviewDetails = async () => {
      try {
        const fetchedMappedJobRoles = await service.callService(getCompanyJobRoleMap);
        const sortedJobRoles = Object.values(fetchedMappedJobRoles)
          .sort((a, b) => a.name.localeCompare(b.name));
        setMappedJobRoles(sortedJobRoles);
        
        const tagFilter = {
          category: "jobRole",
          include: ['domain']
        };
        const fetchedJobRoles = await service.callService(getTags, tagFilter);
        const filteredJobRoles = fetchedJobRoles.tags
          .filter(tag => tag.domain)
          .sort((a, b) => a.code.localeCompare(b.code))
          .reduce((uniqueJobRoles, currentTag) => {
            if (!uniqueJobRoles.some(existingTag => existingTag.code === currentTag.code)) {
              return [...uniqueJobRoles, { ...currentTag, name: currentTag.name }];
            }
            return uniqueJobRoles;
          }, []);

        setJobRolesForResume(filteredJobRoles);

        const domainsResponse = await service.callService(getDomains);
        setDomains(domainsResponse.interviewDomains.sort((a, b) => a.name.localeCompare(b.name)));
      } catch (error) {
        console.error('Error fetching interview details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInterviewDetails();
  }, []);

  const handleSubmit = ({ setSubmitting }) => {
    nextStepAudio();
    
    if (selectedDocument === DocumentOptions.RESUME._id) {
      if (!openJobRoleField && jobRole) {
        next(stepNumber + 1);
      } else if (openJobRoleField && jobRole && domain) {
        next(stepNumber + 1);
      }
    } else {
      if (manualCompanyName) {
        if (companyName && jobRole && domain && jobDescription) {
          next(stepNumber + 1);
        }
      } else {
        if (companyName && jobRole && domain && jobDescription) {
          next(stepNumber + 1);
        }
      }
    }
    setIsDataChanged(false);
    setSubmitting(false);
  };

  useEffect(() => {
    if (jobRole?.companies) {
      setCompanies(jobRole.companies);
    }
  }, [jobRole]);

  const initialValues = {
    companyName: companyName || '',
    selectCompany: companyName || '',
    jobRole: jobRole || '',
    domain: domain || null,
    jobDescription: jobDescription || '',
    manualCompanyName: manualCompanyName || false,
    openJobRoleField: openJobRoleField || false
  };

  if (loading) {
    return   <Box style={{
                width: '100%', height: '400px', marginTop: '52px', display: 'flex', 
                justifyContent: 'center', alignItems: 'center' 
                }}>
                <span className={classes.loader}></span>
            </Box> 
  }

  return (
  <Box className={classes.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={selectedDocument === DocumentOptions.RESUME._id ? resumeValidationSchema : jobDetailsValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({ errors, touched, handleChange, setFieldValue }) => (
          <Form style={{height : '100%'}}>
            {selectedDocument === DocumentOptions.RESUME._id ? (
              <Box className={classes.jobDetailsBotContents}>
                 <Typography variant='h5-medium' color='neutral.clr-600'>
                  You are practicing for job role independent of any company
                </Typography>
                <Box className={classes.fieldContainer} >
                  {openJobRoleField ? (
                    <>
                      <TextField
                        name="jobRole"
                        label="Enter Job role"
                        style={{ width: '336px' }}
                        placeholder="Job role"
                        value={jobRole}
                        onChange={(e) => {
                          handleChange(e);
                          setJobRole(e.target.value);
                        }}
                        error={touched.jobRole && errors.jobRole}
                      />
                      <AutoCompleteSelect
                        name="domain"
                        options={domains}
                        value={domain}
                        label="Select Domain"
                        style={{ width: '336px' }}
                        placeholder="Select Domain"
                        onSelect={(data) => {
                          setFieldValue('domain', data);
                          setDomain(data);
                        }}
                        error={touched.domain && errors.domain}
                        menuStyle={{ maxHeight: '200px' }}
                        width="330px"
                      />
                    </>
                  ) : (
                    
                    <AutoCompleteSelect
                      name="selectJobRole"
                      options={jobRolesForResume}
                      value={jobRole}
                      label="Select Job Role"
                      style={{ width: '336px' }}
                      onSelect={(data) => {
                        setFieldValue('jobRole', data);
                        setJobRole(data);
                        setDomain(data?.domain);
                      }}
                      error={touched.jobRole && errors.jobRole}
                      menuStyle={{ maxHeight: '200px' }}
                      width="330px"
                    />
                  )}
                </Box>

                <Box className={classes.bottomButton}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    style={{width : '100%'}}
                    size="medium"
                    disabled={openJobRoleField ? !jobRole || !domain : !jobRole}
                  >
                    Next
                  </CustomButton>

                  {!openJobRoleField 
                    ? (
                      <Typography
                        className={classes.noJobRole}
                        variant="body01-link"
                        color="primary.clr-300"
                        onClick={() => {
                          setOpenJobRoleField(true);
                          setJobRole('');
                          setDomain('')
                        }}
                      >
                        Didn't find the job role you are looking for? Click here
                      </Typography>
                    )
                  : (
                      <Typography
                        className={classes.noJobRole}
                        variant="body01-link"
                        color="primary.clr-300"
                        onClick={() => {
                          setOpenJobRoleField(false);
                          setJobRole('');
                          setDomain('')
                        }}
                      >
                         Go back and choose from the list of available job roles
                      </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              <Box className={classes.jobDetailsBotContents}>
                {manualCompanyName ? (
                  <>
                    <Box className={classes.fieldContainer}>
                    <Typography variant='h5-medium' color='neutral.clr-600' sx={{marginBottom : '12px'}}>
                        Create for your dream company
                      </Typography>
                      <TextField
                        name="companyName"
                        label="Enter company name"
                        style={{ width: '336px' }}
                        placeholder="Company name"
                        value={companyName}
                        onChange={(e) => {
                          handleChange(e);
                          setCompanyName(e.target.value);
                        }}
                        disabled={clientMock}
                        error={touched.companyName && errors.companyName}
                      />
                      <TextField
                        name="jobRole"
                        label="Enter Job role"
                        style={{ width: '336px' }}
                        placeholder="Job role"
                        value={jobRole}
                        onChange={(e) => {
                          handleChange(e);
                          setJobRole(e.target.value);
                        }}
                        disabled={clientMock}
                        error={touched.jobRole && errors.jobRole}
                      />
                      {clientMock ? (
                        <SimpleSelect
                          name="domain"
                          options={domains}
                          label="Select Domain"
                          style={{ width: '336px' }}
                          placeholder="Select Domain"
                          value={{ _id: domain }}
                          disabled={true}
                          error={touched.domain && errors.domain}
                        />
                      ) : (
                        <AutoCompleteSelect
                          name="domain"
                          options={domains}
                          value={domain}
                          label="Select Domain"
                          style={{ width: '336px' }}
                          placeholder="Select Domain"
                          onSelect={(data) => {
                            setFieldValue('domain', data);
                            setDomain(data);
                          }}
                          error={touched.domain && errors.domain}
                          menuStyle={{ maxHeight: '200px' }}
                          width="330px"
                        />
                      )}
                    </Box>

                   <Box className={classes.fieldContainer}>
                    <TextArea
                        name="jobDescription"
                        label={clientMock ? 'Job description (view only)' : 'Job description'}
                        rows={4}
                        placeholder="Paste company job description here..."
                        value={jobDescription}
                        style={{ width: '336px' }}
                        onChange={(e) => {
                          handleChange(e);
                          setJobDescription(e.target.value);
                        }}
                        disabled={clientMock}
                        error={touched.jobDescription && errors.jobDescription}
                      />
                  
                   </Box>
                   <Box className={classes.bottomButton}>
                      <CustomButton
                          type="submit"
                          variant="contained"
                          style={{ width: '336px' , height : '44px' }}
                          size="medium"
                          disabled={!companyName || !jobRole || !domain || !jobDescription}
                        >
                          Next
                      </CustomButton>
                      <Typography
                          className={classes.noJobRole}
                          variant="body01-link"
                          color="primary.clr-300"
                          onClick={() => {
                            setManualCompanyName(false);
                            setJobDescription('');
                            setCompanyName('');
                            setJobRole('');
                            setDomain('');
                          }}
                        >
                          Go back and choose from the list of available companies
                        </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                     <Box className={classes.fieldContainer}>
                      <Typography variant='h5-medium' color='neutral.clr-600' sx={{marginBottom : '12px'}}>
                          Select job profile details for the interview
                      </Typography>
                      <AutoCompleteSelect
                          name="selectJobRole"
                          options={mappedJobRoles}
                          value={jobRole}
                          label="Select Job Role"
                          style={{ width: '336px' }}
                          onSelect={(data) => {
                            setFieldValue('jobRole', data);                  
                            setJobRole(data);
                            setCompanies(data.companies)
                            setDomain(data?.domain);
                          }}
                          error={touched.jobRole && errors.jobRole}
                          menuStyle={{ maxHeight: '200px' }}
                          width="330px"
                        />
                      <AutoCompleteSelect
                          name="selectCompany"
                          options={companies}
                          value={companyName}
                          label="Company name"
                          style={{ width: '336px' }}
                          onSelect={(data) => {
                            setFieldValue('selectCompany', data);
                            setFieldValue('companyName', data);
                            setCompanyName(data);
                            setJobDescription(data?.jobDescriptions?.content);
                          }}
                          error={touched.companyName && errors.companyName}
                          menuStyle={{ maxHeight: '200px' }}
                          width="330px"
                          disabled={!jobRole}
                        />
                        <TextArea
                          name="jobDescription"
                          label="Job description (view only)"
                          rows={4}
                          placeholder="Paste company job description here..."
                          value={jobDescription}
                          style={{ width: '336px' }}
                          disabled={!companyName && !jobRole}
                          onChange={(e) => {
                            handleChange(e);
                            setJobDescription(e.target.value);
                          }}
                          error={touched.jobDescription && errors.jobDescription}
                          inputProps={{ readOnly: true }}
                          />
                      </Box>
                     <Box className={classes.bottomButton}>
                      <CustomButton
                          type="submit"
                          variant="contained"
                          style={{ width: '100%' }}
                          size="medium"
                          disabled={!companyName || !jobRole || !jobDescription}
                        >
                          Next
                        </CustomButton>

                        <Typography
                          className={classes.noJobRole}
                          variant="body01-link"
                          color="primary.clr-300"
                          onClick={() => {
                            setManualCompanyName(true);
                            setJobDescription('');
                            setCompanyName('');
                            setJobRole('');
                            setDomain('');
                          }}
                        >
                          Didn't find the company or job role you are looking for? Click here
                        </Typography>
                     </Box>
                </>
              )}
            </Box>
          )}
        </Form>
      )}
    </Formik>
  </Box>
);
};

export default SelectJobDetails;