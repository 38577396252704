import React , {useState , useEffect} from 'react';
import { Box, Dialog, Typography, Checkbox, FormControlLabel, Divider } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import makeStyles from "@mui/styles/makeStyles";

import { RadioSelect, SimpleSelect } from 'components/CustomSelectFields';
import TextField, { TextArea } from 'components/CustomFields';
import CustomDatePicker from 'components/CustomDatePicker';
import CustomButton from 'components/CustomButton';


const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    margin: theme.spacing(7),
    marginTop : theme.spacing(20)
  },
  header : {
    position : 'fixed',
    padding : theme.spacing(7),
    paddingBottom : theme.spacing(5),
    display : 'flex',
    flexDirection : 'column',
    gap : theme.spacing(2),
    backgroundColor : theme.palette.shades['clr-white-900'],
    width : '378px',
    zIndex : 10
  },
  checkBox : {
    margin : 0,
    padding : 0,
    paddingRight : '8px',
  },
  backArrow:{
    backgroundColor:theme.palette.shades['clr-white-900'] , 
    color:theme.palette.neutral.main,
    paddingLeft:0,
    paddingRight:0,
    width:'24px',
    height:'24px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer',
    '&:hover': {
    backgroundColor: theme.palette.neutral['clr-100'], 
  },
  },
}));

const DetailsForm = ({
  open,
  onClose,
  formName,
  selectedIndex,
  fields,
  onSubmit,
  initialData = {},
  buttonText = 'Next',
  ...dialogProps
}) => {
  const classes = useStyles();

  const [disableDatePicker , setDisableDatePicker] = useState(false)
  const [ disableYear , setDisableYear] = useState(false)

  const validationSchema = Yup.object(
    fields.reduce((schema, field) => {
      if (field.validation) {
        schema[field.id] = field.validation;
      }
      return schema;
    }, {})
  );

  const formik = useFormik({
    initialValues: fields.reduce((values, field) => {
      if (field.type === 'checkbox') {
        values[field.id] = initialData[field.id] || false;
      } else if (field.type === 'datepicker') {
        values[field.id] = initialData[field.id] || null;
      } else {
        values[field.id] = initialData[field.id] || '';
      }
      return values;
    }, {}),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {     
      const submittedData = {
        ...values,
        id: selectedIndex,
      };
      onSubmit(submittedData);
      onClose();
    },
  });

  useEffect(() => {
    
    if (formik.values['currentWorking']) {
      setDisableDatePicker(true);
      const currentDate = new Date();
      const currentMonthYear = currentDate.toLocaleString('default', { month: 'short', year: 'numeric' });
      formik.setFieldValue('endDate', currentMonthYear);
    } else if (!formik.values['startDate']){
      setDisableDatePicker(true);
    } else {
      setDisableDatePicker(false);
    }
  }, [formik.values['currentWorking'] ,formik.values['startDate'] ]);

  useEffect(() => {
    if (!formik.values['startYear']) {
      setDisableYear(true);
    } else {
      setDisableYear(false);
    }
  }, [formik.values['startYear']]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '378px', 
          height: '608px',
        },
        '& .MuiBackdrop-root': {
           backgroundColor: 'rgba(0, 17, 38, 0.7)',
           backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
        },
      }}
      {...dialogProps}
    >
      <Box className={classes.header}>
          <Box display="flex" gap="8px" alignItems="center">
            <Box
                className={classes.backArrow}
                onClick={onClose}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M6 12.25L1 7M1 7L6 1.75M1 7H13" stroke="#1961BF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </Box>
            <Typography variant="h6-medium" color="neutral.clr-900">
              {formName}
            </Typography>
          </Box>
          <Divider sx={{borderColor : '#CCD4DE'}}/>
        </Box>
      <Box className={classes.parentContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" flexDirection="column" gap="24px">
            {fields.map((field) => {
              if (field.type === 'select') {
                return (
                  <SimpleSelect
                    key={field.id}
                    id={field.id}
                    name={field.id}
                    options={field.options}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[field.id]}
                    error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                    helperText={formik.touched[field.id] && formik.errors[field.id]}
                    label={field.label}
                    sx={{ width: '316px' }}
                  />
                );
              } else if (field.type === 'textarea') {
                return (
                  <TextArea
                    key={field.id}
                    id={field.id}
                    name={field.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[field.id]}
                    error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                    helperText={formik.touched[field.id] && formik.errors[field.id]}
                    label={field.label}
                    placeholder={field.placeholder}
                    rows={field.rows || 4}
                  />
                );
              } else if (field.type === 'radio') {
                return (
                  <Box key={field.id}>
                    <Typography variant="body01-bold">{field.id === 'employmentType' ? 'Employment Type' : 'Course type'}</Typography>
                    <RadioSelect
                      options={field.options}
                      value={formik.values[field.id]}
                      onChange={(event) => {
                        formik.setFieldValue(field.id, event.target.value);
                      }}
                      optionWidth={field.optionWidth || 150}
                      optionHeight='50px'
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    />
                  </Box>
                );
              } else if (field.type === 'checkbox') {
                return (
                  <FormControlLabel
                    key={field.id}
                    style={{margin : 0}}
                    control={
                      <Checkbox
                        id={field.id}
                        name={field.id}
                        checked={formik.values[field.id]}
                        onChange={formik.handleChange}
                        className={classes.checkBox}
                      />
                    }
                    label={field.label}
                  />
                );
              } else if (field.type === 'startYearPicker') {
                return (
                  <CustomDatePicker
                    id={field.id}
                    type={field.datePickerType || "year"}
                    onChange={(date) => formik.setFieldValue(field.id, date)}
                    value={formik.values[field.id]}
                    label={field.label}
                    width={field.width || '600px'}
                    endYear={field.endYear || 2030}
                    error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                    helperText={formik.touched[field.id] && formik.errors[field.id]}
                  />
                );
              }else if (field.type === 'endYearPicker') {
                let startYearDate = null;
              
                if (field.id === 'endDate') {
                  const startDateValue = formik.values['startDate'];
              
                  if (startDateValue) {
                    const [monthStr, yearStr] = startDateValue.split(" ");
                    const month = new Date(`${monthStr} 1, 2000`).getMonth();
                    const year = parseInt(yearStr, 10);
                    startYearDate = new Date(year, month, 1);
                  }
                  return (
                    <CustomDatePicker
                      id={field.id}
                      type={field.datePickerType || "year"}
                      onChange={(date) => formik.setFieldValue(field.id, date)}
                      value={formik.values[field.id]}
                      label={field.label}
                      width={field.width || '600px'}
                      endYear={field.endYear || 2030}
                      minDate={startYearDate}
                      disabled={ disableDatePicker } 
                      error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                      helperText={formik.touched[field.id] && formik.errors[field.id]}
                    />
                  );
                } else {
                  const startYearValue = formik.values['startYear'];
                  startYearDate = startYearValue
                    ? new Date(parseInt(startYearValue, 10), 11, 31)
                    : null;
                    return (
                      <CustomDatePicker
                        id={field.id}
                        type={field.datePickerType || "year"}
                        onChange={(date) => formik.setFieldValue(field.id, date)}
                        value={formik.values[field.id]}
                        label={field.label}
                        width={field.width || '600px'}
                        endYear={field.endYear || 2030}
                        minDate={startYearDate}
                        disabled={ disableYear } 
                        error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                        helperText={formik.touched[field.id] && formik.errors[field.id]}
                      />
                    );
                }     
              } else {
                return (
                  <TextField
                    key={field.id}
                    id={field.id}
                    name={field.id}
                    type={field.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[field.id]}
                    error={formik.touched[field.id] && Boolean(formik.errors[field.id])}
                    label={field.label}
                    placeholder={field.placeholder}
                    helperText={formik.touched[field.id] && formik.errors[field.id]}
                  />
                );
              }
            })}
          </Box>
          <CustomButton type="submit" sx={{ marginTop: '24px', width: '100%' }}>
            {buttonText}
          </CustomButton>
        </form>
      </Box>
    </Dialog>
  );
};

export default DetailsForm;