import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography, TextField } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import dayjs from 'dayjs';
import makeStyles from "@mui/styles/makeStyles";

import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { useServices } from 'contexts';
import { deleteResumeById, extractPdf, getResumes, saveResume, updateResume } from 'services';
import { Session } from 'utils';
import { useSnackbar } from "contexts";
import  { IconButton } from 'components/CustomButton';
import ConfirmationDialog from 'dialogs/ConfirmationDialog';
import ImageViewer from 'components/ImageViewer';
import theme from 'theme';
import useStore from '../CreateInterview/store';
import FileUpload from 'components/FileUpload';
import FileUploadErrorDialog from 'dialogs/FileUploadErrorDialog';
import LinearProgressBar from 'components/LinearProgressBar';

const useStyles = makeStyles(theme => ({
  resumeUploadHeading: {
    display: 'flex',
    flexDirection: 'row'
  },
  newChip: {
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.tertiary['clr-50'],
    border: '2px solid',
    borderColor: theme.palette.tertiary['clr-700'],
    borderRadius: '20px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  createResumeBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center'
  },
  progressionBox:{
    display:'flex',
    flexDirection:'column',
    gap: theme.spacing(3),
    justifyContent: 'center'
  },
  createResumeContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center'
  },
  parentContainer:{
    display:'flex',
    gap:theme.spacing(10),
    width:'100%'
  },
  childContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(10)
  },
  uploadedResumeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding : theme.spacing(0,6),
    overflowY: 'auto'
  },
  previewResumeContainer:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'100%'
  },
  uploadedResumeBox: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    gap: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-400'],
    borderRadius: '4px',
    // width: '492px',
    cursor: 'pointer', 
    transition: 'box-shadow 0.3s ease, border-color 0.3s ease',
    '&.selected': {
      border: '2px solid',
      borderColor: theme.palette.primary.main,
    },
    '&:hover': {
      boxShadow: '0px 2px 8px 0px #00000029', 
    }
  },
  uploadedResumeDetailsBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%'
  },
  topHeadBox: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    gap:theme.spacing(2)
  },
  iconsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: "100%"
  },
  editBox: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  deleteImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resumeName:{
    // minWidth:'110px'
  },
  textWrap: {
    width: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  textField: {
    '& .MuiInputBase-root': {
      padding: 0,
      paddingLeft:'4px',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      border:'1px dashed',
      borderColor: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiInputAdornment-root': {
      marginRight: 0,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'inherit',
    },
    width: '160px',
    minWidth: '100px',
    backgroundColor: 'transparent',
  },
  withoutResumeBox:{
    display:"flex",
    gap:theme.spacing(2),
    justifyContent:'center',
    alignItems:'center',
  },
  footHead: {
    width: '300px',
    height:'72px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  footHeadLast:{
    width: '100px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
  },
  errorImage:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  progressBarContainer:{
    // width:'492px',
    height:'100px',
    borderRadius:theme.spacing(1),
    border:`1px solid ${theme.palette.neutral['clr-100']}`,
    padding:theme.spacing(3.5,3)
  }
}));

export const NoResumeState = ({ 
  nextStep, 
  profilePageResume=false ,
  clientMock ,
  fileUploadButton,
  progressBar,        
  setProgressBar,        
  fileNameReceived,       
  setFileNameReceived  ,
  fileName,
  isFileUploaded,
  error,
  setRefresh
  }) => {
  const classes = useStyles();

  useEffect(()=> {
    if(!profilePageResume && fileNameReceived){
      setProgressBar(true)
    }
  },[fileNameReceived])

  return (
    <Box className={progressBar ? classes.progressionBox : classes.createResumeBox}>
      
      {progressBar 
        ? (  
            <Box className={classes.progressBarContainer}>
              <LinearProgressBar 
                fileNameReceived={fileNameReceived}
                setFileNameReceived={setFileNameReceived}
                isFileUploaded={isFileUploaded}
                fileName={fileName}
                error={error}
                setProgressBar={setProgressBar}
                setRefresh={setRefresh}
              />
            </Box>
          ) 
        : (<Box className={classes.createResumeContent}>
            <img
              src='https://assets.languify.in/images/createResume.png'
              alt='resume'
              width={156}
            />
            <Typography variant='h5-medium' color='neutral.main'>
              You do not have any resume!
            </Typography>
          </Box>)}
          { fileUploadButton}
          {(clientMock && !profilePageResume) && (
              <Box className={classes.withoutResumeBox}>
              <Typography 
                onClick={nextStep} 
                variant='body01-link' 
                color='primary.clr-300' 
                sx={{cursor:'pointer'}}
              >
                Continue without resume
              </Typography>
              <Typography variant='body01-medium' color='neutral.clr-500'>
                (Personalization is not possible)
              </Typography>
            </Box>
        )}
    </Box>
  )
}

export const ResumeDetailBox = ({ 
  resumes, 
  setRefresh, 
  height='305px', 
  selectedBox , 
  onBoxClick,
  personalizedInterview, 
  setSelectedBox,
  fileNameReceived,
  setFileNameReceived,
  fileName,
  isFileUploaded, 
  progressBar,
  setProgressBar,
  error,
  ...props 
}) => {
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar();

  const setSelectedResumeDetails = useStore((store) => store.setSelectedResumeDetails);
  const setParsedData = useStore((store) => store.setParsedData);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);
  const [editingResume, setEditingResume] = useState(null);
  const [editedName, setEditedName] = useState(""); 

  const handleTrashClick = (e,resume) => {
    e.stopPropagation();
    setSelectedResume(resume);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedResume(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedResume) {
      try {
        await service.callService(deleteResumeById, selectedResume._id);
        setRefresh(true);
        setDeleteDialogOpen(false);
        setSelectedResumeDetails("")
        setParsedData("")
        setSelectedBox(null)
        setFileNameReceived(false)
        setProgressBar(false)
      } catch (error) {
        console.error('Failed to delete resume:', error);
        snackbar.showSnackbar(error.message, "error", true);
      } finally {
        handleDialogClose();
      }
    }
  };

  const handleEditClick = (e,resume) => {
    e.stopPropagation();
    setEditingResume(resume);
    setEditedName(resume.name);
  };

  const handleEditSave = async (e) => {
    e.stopPropagation();
    if (editingResume) {
      try {
        await service.callService(updateResume, editingResume._id, { name: editedName });
        setRefresh(true);
        setEditingResume(null);
      } catch (error) {
        console.error('Failed to update resume name:', error);
        snackbar.showSnackbar(error.message, "error", true);
      }
    }
  };

  const handleEditCancel = (e) => {
    e.stopPropagation();
    setEditingResume(null);
  };

  useEffect(()=> {
    if(fileNameReceived){
      setProgressBar(true)
    }
  },[fileNameReceived,progressBar])

  return (
     <Box className={classes.uploadedResumeContainer} sx={{ maxHeight:{ height}, ...props }}>
     {progressBar && fileNameReceived && ( <Box className={classes.progressBarContainer}>
                                              <LinearProgressBar 
                                                fileNameReceived={fileNameReceived}
                                                setFileNameReceived={setFileNameReceived}
                                                isFileUploaded={isFileUploaded}
                                                fileName={fileName}
                                                error={error}
                                                setProgressBar={setProgressBar}
                                                setRefresh={setRefresh}
                                              />
                                            </Box>)}
      {resumes.map((resume, index) => (
        <Box
          key={index}
          className={`${classes.uploadedResumeBox} ${selectedBox === index ? 'selected' : ''}`}
          onClick={() => onBoxClick(index)}>
          <img
            src='https://assets.languify.in/images/resumeUploaded.png'
            alt='resume'
            width={56}
            height={72}
          />
          <Box className={classes.uploadedResumeDetailsBox}>
            <Box className={classes.topHeadBox}>
              <Box className={classes.head}>
                {editingResume === resume 
                ? 
                  (
                    <TextField
                      value={editedName}
                      onChange={(e) => {
                        e.stopPropagation();
                        setEditedName(e.target.value)
                      }}
                      onClick={(e) => e.stopPropagation()}
                      variant="outlined"
                      size="small"
                      autoFocus
                      className={classes.textField}
                      inputProps={{ maxLength: 24 }}
                    />
                  ) 
                : (
                    <Typography
                      className={classes.textWrap} variant='body01-semiBold'>
                      {resume.name}
                    </Typography>
                  )
                }
              </Box>
              <Box className={classes.iconsBox}>
                {editingResume === resume 
                  ? 
                    (
                      <Box className={classes.editBox} onClick={(e) => e.stopPropagation()}>
                        <IconButton
                          sx={{
                            padding: `${theme.spacing(0.5, 0.75)} !important`
                          }}
                          onClick={handleEditSave}
                        >
                          <Check
                            sx={{
                              width: '16px',
                              height: '16px'
                            }} />
                        </IconButton>
                        <IconButton
                          variant='outlined'
                          sx={{
                            padding: `${theme.spacing(0.5, 0.75)} !important`
                          }}
                          onClick={handleEditCancel}
                        >
                          <Clear
                            sx={{
                              width: '16px',
                              height: '16px'
                            }} />
                        </IconButton>
                      </Box>
                    ) 
                  : 
                    (
                      <>
                        <Box>
                          <img
                            src='https://assets.languify.in/images/editResume.svg'
                            alt='edit'
                            onClick={(e) => handleEditClick(e,resume)}
                          />
                        </Box>
                        <Box>
                          <img
                            src='https://assets.languify.in/images/trashResume.svg'
                            alt='trash'
                            onClick={(e) => handleTrashClick(e,resume)}
                          />
                        </Box>
                      </>
                    )
                }
              </Box>
            </Box>
            <Divider variant='light' />
            <Box className={classes.head}>
              <span>
                <Typography variant='body01-medium' color='neutral.clr-900'>
                  Created on:
                </Typography>
              </span>&nbsp;
              <span>
                <Typography variant='body01-semiBold'>
                  {dayjs(resume.createdAt).format('DD/MM/YYYY')}
                </Typography>
              </span>
            </Box>
            <Box className={classes.head}>
              <span >
                <Typography variant='body01-medium' color='neutral.clr-900'>
                  Last used for:
                </Typography>
              </span>&nbsp;
              <span className={classes.footHeadLast}>
                <Typography variant='body01-semiBold'>
                  {resume?.lastUsedForTemplate?.name || 'NA'}
                </Typography>
              </span>
            </Box>
          </Box>
        </Box>
      ))}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onPrimaryAction={handleConfirmDelete}
        onSecondaryAction={handleDialogClose}
        title={<Box color='danger.clr-700'>Delete resume!</Box>}
        message='You are about to delete the resume. Are you sure?'
        secondaryAction="Go back"
        primaryAction="Delete"
        primaryActionType="contained"
        secondaryActionType="outlined"
        buttonSize='medium'
        setRefresh={setRefresh}
        mobileView={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "336px", 
            },
          },
        }}
      />
    </Box>
  );
};


const ResumeDetails = ({ 
  resumes, 
  setRefresh , 
  fileUploadButton , 
  fileName , 
  fileNameReceived ,
  setFileNameReceived,
  isFileUploaded,
  error,
  progressBar,
  setProgressBar,
}) => {
  const classes = useStyles();

  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedResumeDetails, setSelectedResumeDetails] = useState({
    name: "",
    createdOn: "",
    lastUsedFor: ""
  });

  const handleBoxClick = (index) => {
    const selectedResume = resumes[index];
    setSelectedBox(index);
    setSelectedResumeDetails({
      name: selectedResume?.name,
      createdOn: dayjs(selectedResume?.createdAt).format('DD/MM/YYYY'),
      lastUsedFor: selectedResume?.lastUsedFor
    });
  };

  return (
   <Box className={classes.parentContainer}>
     <Box className={classes.childContainer}>
      <ResumeDetailBox 
        resumes={resumes} 
        setRefresh={setRefresh} 
        selectedBox={selectedBox}
        onBoxClick={handleBoxClick}
        setSelectedBox={setSelectedBox}
        fileName={fileName}
        fileNameReceived={fileNameReceived}
        setFileNameReceived={setFileNameReceived}
        isFileUploaded={isFileUploaded}
        error={error}
        progressBar={progressBar}
        setProgressBar={setProgressBar}
        />
      {fileUploadButton}
    </Box>
    <Box className={classes.previewResumeContainer}></Box>
   </Box>
  );
};

const Resume = () => {
    const classes = useStyles();
    const service = useServices();
    const snackbar = useSnackbar();

    const [resumes, setResumes] = useState([]);
    const [resumeNames, setResumeNames] = useState([]);
    const [refresh , setRefresh] = useState(true)
    const [error, setError] = useState(false);
    const [fileName , setFileName] = useState('')
    const [fileNameReceived , setFileNameReceived] = useState(false)
    const [scrapedData , setScrapedData] = useState('')
    const [isFileUploaded , setIsFileUploaded] = useState(false)
    const [progressBar , setProgressBar] = useState(false)
  
    const setSelectedResumeId = useStore(store => store.setSelectedResumeId);
  
    useEffect(() => {
      const saveUploadedResume = async () => {
        if (isFileUploaded) {
          const resumeData = {
            name: fileName,
            parsedData: scrapedData,
            user: Session.userId,
          };
          try {
            const result = await service.callService(saveResume, resumeData);
            setSelectedResumeId(result._id);
            setIsFileUploaded(false);
            setError(false);
          } catch (error) {
            console.log(error);
            setError(true);
            setFileNameReceived(false);
            snackbar.error("Uh Oh! Unable to save resume");
            setIsFileUploaded(false);
          }
        }
      };

      saveUploadedResume();
    }, [isFileUploaded]);

    useEffect(()=> {
      if(scrapedData && fileName){
        setIsFileUploaded(true)
      }
    },[scrapedData , fileName])
    
    const pdfToTextScrape = async (file) => {
        try {
          const result = await service.callService(extractPdf, file);
          return result;
          
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
          throw error;
        }
      };
  
    const handlePdfScrape = async(selectedFile) => {
      const scrapedData = await extractResumeData(selectedFile);
      if(scrapedData){
        setScrapedData(scrapedData)
        let fileNameWithoutExtension = selectedFile.name.replace(/\.pdf$/i, '');

        fileNameWithoutExtension = generateUniqueFileName(fileNameWithoutExtension, resumeNames);
    
        setFileName(fileNameWithoutExtension)
        setFileNameReceived(true)
        return true;
      }
    }
  
    const extractResumeData = async (file) => {
      try {
        const extractedText = await pdfToTextScrape(file);
        if (!extractedText || extractedText.trim().length === 0) {
          setError(true)
          return null;
        }else{
          return extractedText;
        }
      } catch (error) {
        setError(true)
        console.error("Failed to extract text from PDF:", error);
        return null;
      }
    };

    const generateUniqueFileName = (baseName, existingFileNames) => {
      if (existingFileNames.length === 0) {
        return baseName;
      }
  
      let uniqueName = baseName;
      let counter = 1;
      const existingNamesSet = new Set(existingFileNames);
  
      while (existingNamesSet.has(uniqueName)) {
        uniqueName = `${baseName}-${String(counter).padStart(2, '0')}`;
        counter++;
      }
  
      return uniqueName;
    };

    useEffect(() => {
      if(refresh){
        fetchResume();
        setRefresh(false)
      }
    }, [refresh]);

    async function fetchResume() {
      try {
        const response = await service.callService(getResumes, { user: Session.userId,include: ['interviewTemplate'] });
        const sortedResumes = response.resumes.sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)));
        setResumes(sortedResumes);

        const names = sortedResumes.map((resume) => resume.name);
        setResumeNames(names);
      } catch (error) {
        console.error(error);
      }
    }

  return (
    <WhiteBox>
        <WhiteBoxHeader
          heading={
            <Box className={classes.resumeUploadHeading}>My Resume&nbsp;
              <Box className={classes.newChip}>
                <Typography variant='body01-bold' color='tertiary.clr-700'>New</Typography>
              </Box>
            </Box>
          }
          subheading='Create and save resumes for mock interview practice'
        />
        {resumes.length > 0  ? (
          <ResumeDetails 
            resumes={resumes} 
            setRefresh={setRefresh} 
            fileName={fileName}
            fileNameReceived={fileNameReceived}
            setFileNameReceived={setFileNameReceived}
            isFileUploaded={isFileUploaded}
            error={error}
            progressBar={progressBar}
            setProgressBar={setProgressBar}
            fileUploadButton={ 
              <FileUpload 
                name="thumbnail"
                label="Upload file"
                onFileSelect={handlePdfScrape}
                uploadOnAws={false}
                setErrors={setError}
                accept={['.pdf']} 
              />}
            />
        ) : (
          <NoResumeState 
            profilePageResume={true}
            progressBar={progressBar}
            fileName={fileName}
            isFileUploaded={isFileUploaded}
            error={error}
            setRefresh={setRefresh}
            fileNameReceived={fileNameReceived}
            setProgressBar={setProgressBar}
            setFileNameReceived={setFileNameReceived}
            fileUploadButton={ 
              <FileUpload 
                name="thumbnail"
                label="Add New Resume"
                onFileSelect={handlePdfScrape}
                uploadOnAws={false}
                accept={['.pdf']} 
                sx={{
                  width: '234px',
                  opacity: progressBar ? 0 : 1,
                }}
                setErrors={setError}
            />}
          />
        )}
          <FileUploadErrorDialog
            open={error}
            fileUploadProp={  
              <FileUpload 
                name="thumbnail"
                label="Upload again"
                accept={['.pdf']} 
                onFileSelect={handlePdfScrape}
                uploadOnAws={false}
                buttonSize='medium'
                sx={{width:'50%'}}
                setErrors={setError}
              />}
            title={<Box color='danger.clr-700'>Resume upload failed!</Box>}
            message={
              <Box className={classes.errorImage}>
              <ImageViewer 
                src='https://assets.languify.in/images/Error_Resume_Save.png' 
                alt='delete' 
                width={170}
                height={170}
              />
              </Box>
            }
            secondaryAction="I don’t want to upload"
            secondaryActionType="outlined" 
            buttonSize='medium'
            onSecondaryAction={() => {
              setError(null);
            }}
            setProgressBar={setProgressBar}
            fileNameReceived={fileNameReceived}
          />
    </WhiteBox>
  );
}

export default Resume;
