import React, { useState , useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

import { nextStepAudio, Session } from 'utils';
import { useServices } from 'contexts';
import { getDafs } from 'services/dafService';
import { extractFieldDetailsFromDaf } from 'utils/pdfScrape';
import { errorStateTexts } from './constants';
import  useStore  from './store';
import FileUpload from 'components/FileUpload';
import LinearProgressBar from 'components/LinearProgressBar';
import FileUploadErrorDialog from 'dialogs/FileUploadErrorDialog';
import CustomButton from 'components/CustomButton';
import { DafInterviewSteps } from './constants';
import { extractPdf } from 'services';

const useStyles = makeStyles((theme) => ({
  container : {
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(4),
    padding:theme.spacing(3,5),
    borderRadius:theme.spacing(1),
    border:`1px solid ${theme.palette.neutral['clr-200']}`,
    width:'100%',
    animation: '$slideInFromRight 0.6s ease-out',
  },
  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(15px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  bottomBox : {
    display:'flex', 
    flexDirection:'column', 
    gap:'8px', 
    alignItems:'center', 
    position:'absolute',
    bottom : '30px'
  }
}));

const DafUpload = ({stepNumber , setStepNumber}) => {
  const classes = useStyles();
  const service = useServices();

  const setDafExtractedData = useStore(store => store.setDafExtractedData)
  const setDafId = useStore(store => store.setDafId)

  const [dafUploadedManually , setDafUploadedManually] = useState(false)
  const [dafExist , setDafExist] = useState(false)

  const [dafOneUploaded , setDafOneUploaded] = useState(false)
  const [dafOneUploading , setDafOneUploading] = useState(false)
  const [showDafOneProgressBar , setShowDafOneProgressBar] = useState(false)
  const [dafOneRefresh , setDafOneRefresh] = useState(false)
  const [dafOneError , setDafOneError] = useState(false)
  const [errorTextDafOne , setErrorTextDafOne] = useState(errorStateTexts.scanned_document)

  const [dafTwoUploaded , setDafTwoUploaded] = useState(false)
  const [dafTwoUploading , setDafTwoUploading] = useState(false)
  const [showDafTwoProgressBar , setShowDafTwoProgressBar] = useState(false)
  const [dafTwoRefresh , setDafTwoRefresh] = useState(false)
  const [dafTwoError , setDafTwoError] = useState(false)
  const [errorTextDafTwo , setErrorTextDafTwo] = useState(errorStateTexts.scanned_document)

  const [details, setDetails] = useState({
    isDaf2 : '',
    name: "",
    state: "",
    district: "",
    stream: "",
    prizes: "",
    sports: "",
    leadership: "",
    extraCurricular: "",
    optionalSubject: "",
    attemptTaken: "",
    educationalQualifications: "",
    employmentDetails: "",
    dafNumber : ""
  });

  const pdfToTextScrape = async (file) => {
    try {
      const result = await service.callService(extractPdf, file);
      return result;
      
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
      throw error;
    }
  };

  useEffect(() => {
    (async () => {
      try {

        const response = await service.callService(getDafs, {
          user : Session.userId
        });

        if(response.dafs[0]._id && !response.dafs[0].uploaded[0] && !response.dafs[0].uploaded[1]){
          setDafUploadedManually(true)
        }
        
        if (response?.dafs?.[0]){
        setDafId(response.dafs[0]._id)
        setDafExist(true)
        }

        if (response.dafs[0].uploaded[0] === true){
          setDafOneUploaded(true)
          setDafOneRefresh(true)
        }
        if (response.dafs[0].uploaded[1] === true){
          setDafTwoUploaded(true)
          setDafTwoRefresh(true)
        }

        setDetails({
          name: response.dafs[0]?.name,
          state:  response.dafs[0]?.state,
          district: response.dafs[0]?.district,
          stream: response.dafs[0]?.stream,
          prizes: response.dafs[0]?.achievements,
          sports: response.dafs[0]?.sports,
          leadership: response.dafs[0]?.leadership,
          extraCurricular: response.dafs[0]?.interests,
          optionalSubject:  response.dafs[0]?.optionalSubjects,
          attemptTaken: response.dafs[0]?.attempts,
          educationalQualifications:  response.dafs[0]?.education,
          employmentDetails: response.dafs[0]?.experience,
        });
      } catch (error) {
        console.warn("Daf not uploaded");
      }
    })();
  }, []);
  
  useEffect(() => {
    const handleUploadingState = (isUploading, setError, setProgressBar) => {
      if (isUploading) {
        setError(false);
        setProgressBar(true);
      }
    };
  
    handleUploadingState(dafOneUploading, setDafOneError, setShowDafOneProgressBar);
    handleUploadingState(dafTwoUploading, setDafTwoError, setShowDafTwoProgressBar);
  
    setDafExtractedData({
      name: details.name,
      user: Session.userId,
      uploaded: [dafOneUploaded, dafTwoUploaded],
      state: details.state,
      district: details.district,
      stream: details.stream,
      attempts: parseInt(details.attemptTaken, 10) || 0,
      achievements: details.prizes,
      interests: details.extraCurricular,
      sports: details.sports,
      leadership: details.leadership,
      optionalSubjects: details.optionalSubject,
      education:  details.educationalQualifications,
      experience: details.employmentDetails,
    });
  }, [dafOneUploading, dafTwoUploading, details]);
  
  const handleDafScrape = async (selectedFile, isDafTwo) => {
    const setDafUploaded = isDafTwo ? setDafTwoUploaded : setDafOneUploaded;
    const setDafUploading = isDafTwo ? setDafTwoUploading : setDafOneUploading;
    const setDafError = isDafTwo ? setDafTwoError : setDafOneError;
    const setDafErrorText = isDafTwo ? setErrorTextDafTwo : setErrorTextDafOne;
    
    setDafOneError(false);
    setDafTwoError(false);

    if (selectedFile.type !== "application/pdf") {
      setDafError(true);
      setDafErrorText(errorStateTexts.not_pdf_file); 
      return false;
    }
  
    const scrapedData = await extractDafData(selectedFile, isDafTwo);
    
  
    if (!scrapedData) {
      setDafError(true);
      setDafErrorText(errorStateTexts.scanned_document)
      return false;
    }

    if ((typeof scrapedData === 'object' && Object.keys(scrapedData).length === 0)) {
      setDafError(true)
      setDafErrorText(errorStateTexts.necessary_field_fail)
      return false
    }
    
    const dafNumber = scrapedData.dafNumber?.trim();
    
    if (dafNumber === "DETAILED APPLICATION FORM [DAF]-I") {
     
      if (isDafTwo) {        
        setDafTwoError(true);
        setErrorTextDafTwo(errorStateTexts.daf_two_error)
        return false;
      }
    } else if (dafNumber === "DETAILED APPLICATION FORM - II") {
      if (!isDafTwo) {
        setDafOneError(true);
        setErrorTextDafOne(errorStateTexts.daf_one_error)
        return false;
      } 
    } 

    const updatedData = { ...scrapedData };

    if (scrapedData.educationalQualifications) {
      updatedData.educationalQualifications = {
        summary: scrapedData.educationalQualifications,
      };
    }
  
    if (scrapedData.employmentDetails) {
      updatedData.employmentDetails = {
        summary: scrapedData.employmentDetails,
      };
    }
  
    setDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedData,
    }));

    setDafUploaded(true);
    setDafUploading(true);
    return true;
  }; 
  
  const extractDafData = async (file , isDAF2) => {
    try {  
      const extractedText = await pdfToTextScrape(file);
      if (!extractedText || extractedText.trim().length === 0) {
        return null;
      }else{
        const result = extractFieldDetailsFromDaf(extractedText, isDAF2);
        return result;
      }
    } catch (error) {
    console.error("Failed to extract text from PDF:", error);
      return null;
    }
  };

  const handleNextStep = () => {
    nextStepAudio();
    
    setStepNumber(stepNumber + 1);
  }

  const createDafManually = () => {
      setDafOneUploaded(false)
      setDafTwoUploaded(false)
  
      setDafExtractedData({
        user :  Session.userId,
        uploaded : [false , false]
      })
  
      setStepNumber(stepNumber + 1);
  }

  const createDafByUpload = () => {
    if(dafUploadedManually){
      setDafUploadedManually(false)
      setDafExist(false)
      setDetails({})
      setDafExtractedData({
        user :  Session.userId,
        uploaded : [false , false]
      })
    }else {
      setDafOneUploaded(false)
      setDafTwoUploaded(false)
      setDetails({})
      setDafExtractedData({
        user :  Session.userId,
        uploaded : [false , false]
      })
  
      setStepNumber(stepNumber + 1);
    }
  }

  return (
    <Box display='flex' flexDirection='column' marginTop='52px'>
      <Box className={classes.container}>
        <Box display='flex' flexDirection='column' gap='4px'>
          <Typography variant='body01-bold' color='neutral.clr-600'>
             {dafExist ? 'Previous DAF exists!' : 'Upload DAF'}
          </Typography>
          <Typography variant='body01-medium' color='neutral.clr-600'>
            {dafExist ? 'Quickly create new interview if the DAF has no changes' : 'Upload both DAFs to get more personalized experience'}
          </Typography>
        </Box>
        {dafUploadedManually 
          ? (
              <Box display="flex" gap="18px">
                <img
                  src="https://assets.languify.in/images/DAF.svg"
                  alt="daf"
                  width={44}
                  height={55}
                />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography variant="body01-semiBold">Manually created DAF</Typography>
                  <Typography
                    variant="body01-link"
                    color="primary.clr-300"
                    sx={{ cursor: "pointer" }}
                    onClick={createDafManually}
                  >
                    Create new
                  </Typography>
                </Box>
              </Box>
            ) 
          : (
              <Box display="flex" flexDirection="column" gap="12px">
                {(showDafOneProgressBar && dafOneUploading) ? (
                  <Box className={classes.progressBarContainer}>
                    <LinearProgressBar
                      fileNameReceived={dafOneUploading}
                      setFileNameReceived={setDafOneUploading}
                      isFileUploaded={dafOneUploaded}
                      fileName="Daf 1"
                      error={dafOneError}
                      setProgressBar={setShowDafOneProgressBar}
                      isCaseStudy={false}
                      setRefresh={setDafOneRefresh}
                    />
                  </Box>
                ) : dafOneRefresh ? (
                  <Box display="flex" gap="18px">
                    <img
                      src="https://assets.languify.in/images/DAF.svg"
                      alt="daf"
                      width={44}
                      height={55}
                    />
                    <Box display="flex" flexDirection="column" gap="4px">
                      <Typography variant="body01-semiBold">DAF-01</Typography>
                      <FileUpload
                        name="daf1-thumbnail"
                        label="Upload DAF-1"
                        onFileSelect={(file) => handleDafScrape(file, false)}
                        setIsFileUploaded={setDafOneUploaded}
                        uploadOnAws={false}
                        accept={[".pdf"]}
                        variant="outlined"
                        buttonSize="medium"
                        setErrors={setDafOneError}
                        buttonType={
                          <Typography
                            variant="body01-link"
                            color="primary.clr-300"
                            sx={{ cursor: "pointer" }}
                          >
                            Change file?
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Typography variant="body01-bold" color="neutral.clr-900">
                      DAF-1 PDF file
                    </Typography>
                    <FileUpload
                      name="daf1-thumbnail"
                      label="Upload DAF-1"
                      onFileSelect={(file) => handleDafScrape(file, false)}
                      setIsFileUploaded={setDafOneUploaded}
                      uploadOnAws={false}
                      accept={[".pdf"]}
                      variant="outlined"
                      buttonSize="medium"
                      setErrors={setDafOneError}
                    />
                  </Box>
                )}
                {(showDafTwoProgressBar && dafTwoUploading) ? (
                  <Box className={classes.progressBarContainer}>
                    <LinearProgressBar
                      fileNameReceived={dafTwoUploading}
                      setFileNameReceived={setDafTwoUploading}
                      isFileUploaded={dafTwoUploaded}
                      fileName="Daf 2"
                      error={dafTwoError}
                      setProgressBar={setShowDafTwoProgressBar}
                      isCaseStudy={false}
                      setRefresh={setDafTwoRefresh}
                    />
                  </Box>
                ) : dafTwoRefresh ? (
                  <Box display="flex" gap="18px">
                    <img
                      src="https://assets.languify.in/images/DAF.svg"
                      alt="daf"
                      width={44}
                      height={55}
                    />
                    <Box display="flex" flexDirection="column" gap="4px">
                      <Typography variant="body01-semiBold">DAF-02</Typography>
                      <FileUpload
                        name="daf2-thumbnail"
                        label="Upload DAF-2"
                        onFileSelect={(file) => handleDafScrape(file, true)}
                        setIsFileUploaded={setDafTwoUploaded}
                        uploadOnAws={false}
                        accept={[".pdf"]}
                        variant="outlined"
                        buttonSize="medium"
                        buttonType={
                          <Typography
                            variant="body01-link"
                            color="primary.clr-300"
                            sx={{ cursor: "pointer" }}
                          >
                            Change file?
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Typography variant="body01-bold" color="neutral.clr-900">
                      DAF-2 PDF file
                    </Typography>
                    <FileUpload
                      name="daf2-thumbnail"
                      label="Upload DAF-2"
                      onFileSelect={(file) => handleDafScrape(file, true)}
                      setIsFileUploaded={setDafTwoUploaded}
                      uploadOnAws={false}
                      accept={[".pdf"]}
                      variant="outlined"
                      buttonSize="medium"
                    />
                  </Box>
                )}
              </Box>
            )
        }
        <FileUploadErrorDialog
              open={dafOneError}
              fileUploadProp={  
                <FileUpload 
                  name="daf1-thumbnail"
                  label="Upload again"
                  onFileSelect={(file) => handleDafScrape(file, false)}
                  uploadOnAws={false}
                  accept={['.pdf']} 
                  variant='contained'
                  buttonSize='medium'
                  setErrors={setDafOneError}
                  sx={{width:'100%'}}
                />}
              title={<Box color='danger.clr-700'>File upload failed!</Box>}
              message={
                <Box className={classes.errorImage}>
                  <Typography variant='h6-regular' color='nuetral.clr-900'>
                    {errorTextDafOne}
                  </Typography>
                </Box>
              }
              buttonSize='medium'
              setProgressBar={setShowDafOneProgressBar}
              fileNameReceived={dafOneUploading}
              isCaseStudy={true}
              dafHyperlink={
                errorTextDafOne === errorStateTexts.necessary_field_fail || 
                errorTextDafOne === errorStateTexts.scanned_document ? true : false
              }
              onClick={createDafManually}
              onSecondaryAction={() => {
                setDafOneError(null);
              }}
            />
          <FileUploadErrorDialog
              open={dafTwoError}
              fileUploadProp={  
                <FileUpload 
                  name="daf2-thumbnail"
                  label="Upload again"
                  onFileSelect={(file) => handleDafScrape(file, true)}
                  uploadOnAws={false}
                  accept={['.pdf']} 
                  variant='contained'
                  buttonSize='medium'
                  setErrors={setDafTwoError}
                  sx={{width:'100%'}}
                />}
              title={<Box color='danger.clr-700'>File upload failed!</Box>}
              message={
                <Box className={classes.errorImage}>
                  <Typography variant='h6-regular' color='nuetral.clr-900'>
                    {errorTextDafTwo}
                  </Typography>
                </Box>
              }
              buttonSize='medium'
              setProgressBar={setShowDafTwoProgressBar}
              fileNameReceived={dafTwoUploading}
              isCaseStudy={true}
              dafHyperlink={
                errorTextDafTwo === errorStateTexts.necessary_field_fail || 
                errorTextDafTwo === errorStateTexts.scanned_document ? true : false
              }
              onClick={createDafManually}
              onSecondaryAction={() => {
                setDafTwoError(null);
              }}
            />
      </Box>
      <Box className={classes.bottomBox}>
        <CustomButton
          size='medium'
          onClick={handleNextStep}
          disabled={dafUploadedManually ? false : !dafOneUploaded && !dafTwoUploaded}
          sx={{width : '336px'}}
        >
           {dafUploadedManually ? 'Click to preview' : 'Next'}
        </CustomButton>
        <Typography 
          onClick={dafUploadedManually ? createDafByUpload : createDafManually}  
          variant='body01-link' 
          color='primary.main'
          sx={{cursor : 'pointer'}}
        >
          {dafUploadedManually ? 'Click here for uploading the DAF' : 'Create new DAF form manually'}
        </Typography>
      </Box>
    </Box>
  )
}

export default DafUpload;