import React , {useState , useEffect , useRef} from 'react'
import { Box, Divider, Skeleton, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import dayjs from 'dayjs';
import theme from 'theme';

import { NoResumeState, ResumeDetailBox } from 'components/Profile/Resume';
import { useServices } from 'contexts';
import { createPersonalizedTemplate, extractPdf, getDraftById, getResumes, getTemplates, saveResume } from 'services';
import { nextStepAudio, Session } from 'utils';
import { DocumentOptions, errorStateTexts, Interviewer, InterviewRounds, InterviewSteps, PersonalizedTemplateType } from './constants';
import { useSnackbar } from "contexts";
import { useNavigateWithClient } from "hooks";
import CustomButton from 'components/CustomButton';
import AppShell from 'components/AppShell';
import useStore from './store';
import Steps from './Steps';
import SelectJobDetails from './SelectJobDetails';
import StartPersonalizedAssessmentDialog from 'dialogs/StartPersonalizedAssessmentDialog';
import FileUpload from 'components/FileUpload';
import FileUploadErrorDialog from 'dialogs/FileUploadErrorDialog';
import ReportBasedInterview  from './ReportBasedInterview';
import Chip from 'components/Chip';
import InterviewRound from './InterviewRound';
import SelectInterviewer from './SelectInterviewer';

const useStyles = makeStyles((theme) => ({
  createContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(11),
    width: '100%',
    height : '100%',
    background: `url('https://assets.languify.in/images/bgImgInterviewWizard.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    alignItems: 'center',
    padding: theme.spacing(7),
    borderRadius: theme.spacing(1),
    position: 'relative',
    minHeight: `calc(100vh - 145px)`,
    height: `calc(100vh - 145px)`,
    [theme.breakpoints.down('1700')]: {
      height: '100%'
    }
},
  topRightImage: {
    position: 'absolute',
    top: '0px',
    right: '0px', 
},
  parentContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(2),
    backgroundColor : theme.palette.shades['clr-white-900'],
    padding:theme.spacing(5,0),
    borderRadius: '4px',
    position:'relative',
    width:'384px',
    maxHeight : '608px',
    minHeight : '608px',
},
  heading:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(3),
    padding : theme.spacing(0,6)
},
  whiteBoxContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(7),
    alignItems:'center',
    padding:theme.spacing(7),
    backgroundColor : theme.palette.shades['clr-white-900'],
    borderRadius: '4px',
    position:'relative',
    width:'800px',
    height:'456px'
},
  whiteBoxContainerJobDetails:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(7),
    alignItems:'center',
    padding:theme.spacing(7),
    backgroundColor : theme.palette.shades['clr-white-900'],
    borderRadius: '4px',
    position:'relative',
    width:'800px'
},
  backArrow:{
    left:'32px',
    top:'32px'
},
  selectResumeContainer : {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:theme.spacing(3),
    position:'relative',
    animation: '$slideInFromRight 0.6s ease-out',
},
resumeBottomContainer:{
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  gap:theme.spacing(3)
},
uploadContainerBottom: {
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5, 0),
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  background: 'linear-gradient(360deg, #FFFFFF 85%, rgba(255, 255, 255, 0) 100%)',
  width: '100%',
  height : '104px',
  position : 'absolute',
  bottom : '-84px'
},
beginInterviewBottom:{
  display:'flex',
  flexDirection:'column',
  position:'absolute',
  bottom: '10px',
  zIndex:'10',
  padding:theme.spacing(4,0),
  justifyContent:'center',
  alignItems:'center',
  gap:theme.spacing(2),
  background: 'linear-gradient(360deg, #FFFFFF 80%, rgba(255, 255, 255, 0) 100%)',
  width : '384px',
  height : '104px',
  left : 0,
  bottom : 0
},
previewContainer:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(5),
  width:'480px'
},
detailsContainer:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(3)
},
mockDetailsContainer:{
  maxHeight:'450px',
  overflowY:'auto',
  display:'flex',
  justifyContent:'center',
  flexDirection : 'column',
  gap : theme.spacing(5)
},
mockDetailsBox:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(3)
},
mockDetail:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(1),
},
resumeDetailConatiner:{
  display:'flex',
  flexDirection:'column',
  gap:theme.spacing(2)
},
uploadedResumeBox: {
  padding: theme.spacing(2, 3),
  display: 'flex',
  gap: theme.spacing(3),
  border: '1px solid',
  borderColor: theme.palette.neutral['clr-400'],
  borderRadius: '4px',
},
uploadedResumeDetailsBox: {
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%'
},
topHeadBox: {
  display: 'flex',
  gap: theme.spacing(2)
},
head: {
  display: 'flex',
  flexDirection: 'row',
  gap:theme.spacing(2)
},
iconsBox: {
  display: 'flex',
  justifyContent: 'space-between',
  width: "100%"
},
noResumeContainer:{
  marginTop: theme.spacing(8),
  display : 'flex',
  flexDirection : 'column',
  gap : theme.spacing(20),
  padding : theme.spacing(0,6)
},
previewBox:{
  height:'320px'
},
noResumeBox:{
  height:'244px'
},
jdPreviewBox:{
  height:'254px'
},
withoutResumeBox:{
  display:"flex",
  gap:theme.spacing(2),
  justifyContent:'center',
  alignItems:'center',
},
skeleton: {
  borderRadius: theme.spacing(3),
  width: '100%', height: '300px',
},
footHeadLast:{
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  width : '120px'
},
errorImage:{
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
},
textWrap: {
  width: '235px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
},
tooltip: {
  backgroundColor: theme.palette.shades['clr-white-900'],
  color: theme.palette.neutral['clr-900'], 
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', 
  borderRadius: '4px',
  padding: theme.spacing(1,2),
  ...theme.typography['body02-bold'],
  minWidth:'490px',
  height:'35px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  textAlign:'center',
  border:`1px solid ${theme.palette.neutral['clr-500']}`
},
arrow: {
  color: theme.palette.shades['clr-white-900'],
  '&::before': {
    border:`1px solid ${theme.palette.neutral['clr-500']}`,  
    backgroundColor: theme.palette.shades['clr-white-900'],   
    boxSizing: 'border-box', 
  },
},
selectDocumentBox : {
  display : 'flex',
  flexDirection : 'column',
  gap : theme.spacing(3),
  width : '100%',
  padding : theme.spacing(0,6),
  maxHeight : '530px',
  overflow : 'auto'
},
 selectDocument: {
    display: 'flex',
    minHeight : '122px',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.neutral['clr-300']}`,
    color: theme.palette.neutral['clr-600'],
    transition: 'box-shadow 0.3s ease', 
    '&.selected': {
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary['clr-300'],
      '&:hover $optionImage': {
        bottom: '-28px', 
        boxShadow: `0px 0px 8px 0px #D2D2D28C`
      },
      '&:hover $optionImageReport': {
        bottom: '4px', 
      },
    },
    '&:not(.selected)': {
      '&:hover': {
        boxShadow: `0px 0px 8px 0px #D2D2D28C`,
         border: `1px solid ${theme.palette.primary.main}`
      },
      '&:hover $optionImage': {
        bottom: '0px', 
        boxShadow: `0px 0px 8px 0px #D2D2D28C`
      },
      '&:hover $optionImageReport': {
        bottom: '15px', 
      },
    },
    cursor : 'pointer'
  },
  optionImageBox:{
    position:'absolute',
    maxHeight:'92px',
    overflowY:'hidden',
    bottom : '0px',
    width:'96px',
    display:'flex',
    justifyContent:'center'
  },
  optionImageBoxReport:{
    position:'absolute',
    maxHeight:'92px',
    overflowY:'hidden',
    bottom : '0px',
    width:'105px',
    display:'flex',
    justifyContent:'center'
  },
  optionImage: {
    position:'relative',
    bottom:'-18px',
    transition: 'bottom 0.5s ease', 
  },
  optionImageReport: {
    position:'relative',
    bottom:'4px',
    transition: 'bottom 0.5s ease', 
  },
  contentContainer:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    gap:theme.spacing(1),
    padding : theme.spacing(1)
  },
  container : {
    display : 'flex',
    flexDirection : 'column', 
    gap : '20px',
    marginTop : '52px',
  },
  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(15px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
}));

const Preview = ({
  noResume , beginInterview , template , isDialogOpen , setIsDialogOpen , 
  isResponseReceived, clientMock , error, interviewer , interviewRound
}) => {
  const classes = useStyles();
  const service = useServices();
  const hasSetNameRef = useRef(false);

  const [
          interviewName,selectedResumeDetails,selectedDocument,
          companyName,jobRole,domain,jobDescription ,setInterviewName,
        ] = useStore(store => [
              store.interviewName,store.selectedResumeDetails,store.selectedDocument,
              store.companyName,store.jobRole,store.domain,store.jobDescription,store.setInterviewName,
              store.setSelectCompany,store.setSelectJobRole,
            ])
 
  useEffect(() => {
    (async () => {
      if (!clientMock && !hasSetNameRef.current) {
        try {
          let name;
          if (selectedDocument === DocumentOptions.RESUME._id) {
            name = jobRole.name || jobRole;
          } else {
            name = `${companyName.name || companyName}: ${jobRole.name || jobRole}`;
          }
  
          const filter = { name: name, isActive: true, user: Session.userId };
          const { interviewTemplates } = await service.callService(getTemplates, filter);
  
          let newInterviewName = name.length > 150 ? name.substring(0, 150) : name;
          let suffix = 1;

          const nameExists = (nameToCheck) => {
            return interviewTemplates.some(template => template.name === nameToCheck);
          };
  
          while (nameExists(newInterviewName)) {
            const paddedSuffix = suffix.toString().padStart(2, '0');
            newInterviewName = `${name}-${paddedSuffix}`;
            suffix++;
          }
  
          setInterviewName(newInterviewName);
          hasSetNameRef.current = true;
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);  
  
  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleInterviewBegin = () => {
    beginInterview()
  }

  const isButtonDisabled = () => {
    if(selectedDocument === DocumentOptions.RESUME._id){
      return !interviewName || !jobRole || !domain 
    } else {
      return !interviewName || !companyName || !jobRole || !domain || !jobDescription
  }
}
  
  return (
      <Box sx={{marginTop : '52px' , padding : theme.spacing(0,6)}}>
        <Box className={classes.selectResumeContainer }>
          <Box className={classes.detailsContainer}>
            <Box className={classes.mockDetailsContainer}>
                <Typography 
                  variant='h5-medium' 
                  color='neutral.clr-900'
                >
                  Preview interview details before you begin...
                </Typography>
              <Box 
                sx={{ display:'flex', flexDirection:'column', gap:theme.spacing(3)}}
              >
                {selectedDocument === DocumentOptions.RESUME._id 
                  ? (
                      <Box className={classes.mockDetailsBox}>
                        <Box className={classes.mockDetail}>
                          <Typography 
                            variant='body01-bold' 
                            color='neutral.clr-600' 
                            >
                              Job role
                          </Typography>
                          <Typography variant='body01-bold'>
                            {jobRole.name || jobRole}
                          </Typography>
                        </Box>
                        <Box className={classes.mockDetail}>
                            <Typography 
                              variant='body01-bold' 
                              color='neutral.clr-600' 
                              >
                                Domain
                            </Typography>
                            <Typography variant='body01-bold'>{domain.name}</Typography>
                        </Box>
                        <Box className={classes.mockDetail}>
                          <Typography 
                            variant='body01-bold' 
                            color='neutral.clr-600' 
                            >
                              Interview round
                          </Typography>
                          <Typography variant="body01-bold">
                            {InterviewRounds.find(round => round._id === interviewRound)?.round || ''}
                          </Typography>
                        </Box>
                        <Box className={classes.mockDetail}>
                            <Typography 
                              variant='body01-bold' 
                              color='neutral.clr-600' 
                              >
                                Interviewer name
                            </Typography>
                            <Typography variant='body01-bold' >
                              {Interviewer.find(interview => interview.voiceId === interviewer)?.name || ''}
                            </Typography>
                        </Box>
                      </Box>
                  ) 
                  : 
                  (
                    <Box className={classes.mockDetailsBox}>
                      <Box className={classes.mockDetail}>
                        <Typography 
                          variant='body01-bold' 
                          color='neutral.clr-600' 
                          >
                            Company name
                        </Typography>
                        <Typography variant='body01-bold' color='neutral.clr-800'>
                          {companyName.name || companyName}
                        </Typography>
                      </Box>
                      <Box className={classes.mockDetail}>
                          <Typography 
                            variant='body01-bold' 
                            color='neutral.clr-600' 
                            >
                              Job role
                          </Typography>
                          <Typography variant='body01-bold' >
                            {jobRole.name || jobRole}
                          </Typography>
                      </Box>
                      <Box className={classes.mockDetail}>
                          <Typography 
                            variant='body01-bold' 
                            color='neutral.clr-600' 
                            >
                              Interview round
                          </Typography>
                          <Typography variant="body01-bold">
                            {InterviewRounds.find(round => round._id === interviewRound)?.round || ''}
                          </Typography>
                      </Box>
                      <Box className={classes.mockDetail}>
                          <Typography 
                            variant='body01-bold' 
                            color='neutral.clr-600' 
                            >
                              Interviewer name
                          </Typography>
                          <Typography variant='body01-bold' >
                            {Interviewer.find(interview => interview.voiceId === interviewer)?.name || ''}
                          </Typography>
                      </Box>
                    </Box>
                  )}
                  {
                    selectedDocument !== DocumentOptions.JD._id && 
                    (
                      <>
                        {selectedResumeDetails && (
                          <>
                            <Divider sx={{border : '1px solid #8692A3'}} />
                          
                            <Box className={classes.resumeDetailConatiner}>
                              <Typography variant='body01-bold' color='neutral.clr-600'>
                                Your selected Resume
                              </Typography>
                              <Box className={classes.uploadedResumeBox}>
                                <img
                                  src='https://assets.languify.in/images/resumeUploaded.png'
                                  alt='resume'
                                  width={56}
                                  height={72}
                                />
                                  <Box className={classes.uploadedResumeDetailsBox}>
                                    <Box className={classes.topHeadBox}>
                                      <Box className={classes.head}>
                                        <Typography
                                          className={classes.textWrap} variant='body01-semiBold'>
                                              {selectedResumeDetails?.name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Divider variant='light' />
                                    <Box className={classes.head}>
                                      <span>
                                        <Typography variant='body01-medium' color='neutral.clr-900'>
                                            Created on:
                                        </Typography>
                                      </span>
                                      <span>
                                        <Typography variant='body01-semiBold'>
                                          {dayjs(selectedResumeDetails?.createdOn).format('DD/MM/YYYY')}
                                        </Typography>
                                      </span>
                                    </Box>
                                    <Box className={classes.head}>
                                      <span>
                                        <Typography variant='body01-medium' color='neutral.clr-900'>
                                          Last used for:
                                        </Typography>
                                      </span>
                                      <span className={classes.footHeadLast}>
                                        <Typography variant='body01-semiBold'>
                                          {selectedResumeDetails?.lastUsedFor?.name || 'NA'}
                                        </Typography>
                                      </span>
                                    </Box>
                                  </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                      </>
                    )
                  }
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.beginInterviewBottom}>
            <Tooltip 
              title={`Creating a new interview will use 1 credit from your available credits`}
              arrow 
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: 'flip',
                    enabled: false, 
                  },
                ],
              }}
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            >
              <Box>
                <CustomButton
                  variant='contained'
                  sx={{ width:'336px'}}
                  onClick={handleInterviewBegin}
                  size='medium'
                  disabled={isButtonDisabled()}
                >
                   Begin Interview
                </CustomButton>
              </Box>
            </Tooltip>
          </Box>
        <StartPersonalizedAssessmentDialog 
          openDialog={isDialogOpen} 
          onClose={handleDialogClose} 
          responseReceived={isResponseReceived}
          template={template}
          error={error}
          noResume={noResume}
        />
      </Box>
    )}

const SelectResume = ({ next, stepNumber, setIsNoResume, clientMock }) => {
    const classes = useStyles();
    const service = useServices();
    const snackbar = useSnackbar();
    
    const [
            selectedResumeId,setSelectedResumeDetails,setParsedData,setSelectedResumeId
          ] = useStore((store)=> [
                                  store.selectedResumeId,store.setSelectedResumeDetails,
                                  store.setParsedData,store.setSelectedResumeId
                                ])
  
    const [resumes, setResumes] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [selectedBox, setSelectedBox] = useState(null);
    const [loading , setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [fileName , setFileName] = useState('')
    const [resumeNames, setResumeNames] = useState([]);
    const [scrapedData , setScrapedData] = useState('')
    const [isFileUploaded , setIsFileUploaded] = useState(false)
    const [progressBar , setProgressBar] = useState(false)
    const [fileNameReceived , setFileNameReceived] = useState(false)
    const [errorText , setErrorText] = useState('')
  
    useEffect(() => {
      const saveUploadedResume = async () => {
        if (isFileUploaded) {
          const resumeData = {
            name: fileName,
            parsedData: scrapedData,
            user: Session.userId,
          };
          try {
            const result = await service.callService(saveResume, resumeData);
            setSelectedResumeId(result._id);
            setIsFileUploaded(false);
            setError(false);
          } catch (error) {
            console.log(error);
            setError(true);
            setFileNameReceived(false);
            snackbar.error("Uh Oh! Unable to save resume");
            setIsFileUploaded(false);
          }
        }
      };

      saveUploadedResume();
    }, [isFileUploaded]);

    useEffect(()=> {
      if(scrapedData && fileName){
        setIsFileUploaded(true)
      }
    },[scrapedData , fileName])
    
    const handlePdfScrape = async(selectedFile) => {

      if (selectedFile.type !== "application/pdf") {
        setError(true);
        setErrorText(errorStateTexts.not_pdf_file); 
        return false;
      }
      
      const scrapedData = await extractResumeData(selectedFile);
      if(scrapedData){
        setScrapedData(scrapedData)
        let fileNameWithoutExtension = selectedFile.name.replace(/\.pdf$/i, '');

        fileNameWithoutExtension = generateUniqueFileName(fileNameWithoutExtension, resumeNames);
    
        setFileName(fileNameWithoutExtension)
        setFileNameReceived(true)

        return true;
      }
    }
  
    const extractResumeData = async (file) => {
      try {
        const extractedText = await pdfToTextScrape(file);
        
        if (!extractedText || extractedText.trim().length === 0) {
          setError(true)
          setErrorText(errorStateTexts.scanned_document)
          return null;
        }else{
          return extractedText;
        }
      } catch (error) {
        setError(true)
        setErrorText(errorStateTexts.pdf_upload_fail)
        console.error("Failed to extract text from PDF:", error);
        return null;
      }
    };

    const pdfToTextScrape = async (file) => {
      try {
        const result = await service.callService(extractPdf, file);
        return result;
        
      } catch (error) {
        console.error("Error extracting text from PDF:", error);
        throw error;
      }
    };

    const generateUniqueFileName = (baseName, existingFileNames) => {
      if (existingFileNames.length === 0) {
        return baseName;
      }
  
      let uniqueName = baseName;
      let counter = 1;
      const existingNamesSet = new Set(existingFileNames);
  
      while (existingNamesSet.has(uniqueName)) {
        uniqueName = `${baseName}-${String(counter).padStart(2, '0')}`;
        counter++;
      }
  
      return uniqueName;
    };

    useEffect(() => {
      const latestResume = resumes
        .filter(resume => resume.lastUsedForTemplate)
        .sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt)))[0];
    
      if (latestResume) {
        setSelectedResumeId(latestResume?._id) 
        setSelectedBox(resumes.indexOf(latestResume)); 
        setSelectedResumeDetails({
          name: latestResume.name,
          createdOn: latestResume.createdAt,
          lastUsedFor: latestResume?.lastUsedForTemplate,
        });
      }
      if (selectedResumeId && resumes.length > 0) {
        const resumeIndex = resumes.findIndex((resume) => resume._id === selectedResumeId);
        
        if (resumeIndex !== -1) {
          const selectedResume = resumes[resumeIndex];
      
          setSelectedBox(resumeIndex);
          setSelectedResumeDetails({
            name: selectedResume.name,
            createdOn: selectedResume.createdAt,
            lastUsedFor: selectedResume?.lastUsedForTemplate,
          });
          setParsedData(selectedResume.parsedData);
          setSelectedResumeId(selectedResume?._id) 
        }
      }
      
    }, [resumes]);
  
    const handleBoxClick = (index) => {

      const selectedResume = resumes[index];
      
      setSelectedBox(index);
      setSelectedResumeDetails({
        name: selectedResume.name,
        createdOn: selectedResume.createdAt,
        lastUsedFor: selectedResume?.lastUsedForTemplate,
      });
      setParsedData(selectedResume.parsedData);
      setSelectedResumeId(selectedResume?._id) 
    };
  
    useEffect(() => {
      if (refresh) {
        fetchResume();
        setRefresh(false);
      }
    }, [refresh]);
  
    async function fetchResume() {
      try {
        setLoading(true);

        const response = 
          await service.callService(getResumes, { user: Session.userId,include: ['interviewTemplate'] });

        const sortedResumes = response.resumes.sort(
            (a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt))
          );

        setResumes(sortedResumes);

        const names = sortedResumes.map((resume) => resume.name);
        setResumeNames(names);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false)
      }
    }

    const handleNextStep = () => {
      nextStepAudio();

      next(stepNumber + 1);
    }
  
    const handleNextStepWithoutResume = () => {
      next(stepNumber + 1);
      setSelectedResumeDetails(null);
      setIsNoResume(true);
      setParsedData(null);
    };
  
    return (
      <Box className={classes.container}>
        <Box className={classes.selectResumeContainer}>
          {
            loading 
              ? 
                <Box style={{ width: '100%'}}>
                  <Skeleton variant="rectangular" className={classes.skeleton} />
                </Box> 
              :
                resumes.length > 0 
                ?
                  (
                    <>
                      <Box  
                        display='flex' flexDirection='column' gap='20px'
                        sx={{
                            padding : theme.spacing(0,6)
                          }}
                      >
                        <Typography 
                          variant="h5-medium" 
                          color='neutral.clr-600'
                        >
                          Your Resume helps in creating personalized questions
                        </Typography>
                        <Typography variant='body01-bold' sx={{marginBottom : '4px'}}>
                          You can select from available Resumes...
                        </Typography>
                      </Box>
                      <ResumeDetailBox
                        resumes={resumes}
                        setRefresh={setRefresh}
                        height="340px"
                        selectedBox={selectedBox}
                        setSelectedBox={setSelectedBox}
                        onBoxClick={handleBoxClick}
                        personalizedInterview={true}
                        fileName={fileName}
                        fileNameReceived={fileNameReceived}
                        setFileNameReceived={setFileNameReceived}
                        isFileUploaded={isFileUploaded}
                        error={error}
                        progressBar={progressBar}
                        setProgressBar={setProgressBar}
                      />
                        <Box className={classes.uploadContainerBottom}>
                          <Box className={classes.resumeBottomContainer}>
                            <FileUpload 
                              name="thumbnail"
                              label="Upload file"
                              onFileSelect={handlePdfScrape}
                              uploadOnAws={false}
                              variant='outlined'
                              sx={{minWidth:'162px !important'}}
                              setErrors={setError}
                              accept={['application/pdf']} 
                            />
                            <CustomButton
                              variant="contained"
                              sx={{ width: '162px' }}
                              onClick={handleNextStep}
                              disabled={selectedBox === null}
                            >
                              Next
                            </CustomButton>
                          </Box>
                          {clientMock && (
                            <Box className={classes.withoutResumeBox}>
                              <Typography
                                onClick={handleNextStepWithoutResume}
                                variant="body01-link"
                                color="primary.clr-300"
                                sx={{cursor:'pointer'}}
                              >
                                Continue without resume
                              </Typography>
                              <Typography variant="body01-medium" color="neutral.clr-500">
                                (Personalization is not possible)
                              </Typography>
                            </Box>
                          )}
                        </Box>
                    </>
                  ) 
                : 
                  (
                    <Box className={classes.noResumeContainer}>
                      <Typography variant="h5-medium" color='neutral.clr-600'>
                          Your Resume helps in creating personalized questions
                        </Typography>
                      <NoResumeState
                        nextStep={handleNextStepWithoutResume}
                        buttonText="Add new resume"
                        buttonWidth="100%"
                        clientMock={clientMock}
                        progressBar={progressBar}
                        fileName={fileName}
                        isFileUploaded={isFileUploaded}
                        error={error}
                        setRefresh={setRefresh}
                        fileNameReceived={fileNameReceived}
                        setProgressBar={setProgressBar}
                        setFileNameReceived={setFileNameReceived}
                        fileUploadButton={ <FileUpload 
                                              name="thumbnail"
                                              label="Add New Resume"
                                              accept={['application/pdf']} 
                                              onFileSelect={handlePdfScrape}
                                              uploadOnAws={false}
                                              sx={{
                                                width: '234px',
                                                opacity: progressBar ? 0 : 1,
                                              }}
                                              setErrors={setError}
                                          />}
                                  />
                                </Box>
                              )}
         <FileUploadErrorDialog
            open={error}
            fileUploadProp={  <FileUpload 
                                name="thumbnail"
                                label="Upload again"
                                onFileSelect={handlePdfScrape}
                                uploadOnAws={false}
                                buttonSize='medium'
                                sx={{padding : '0px !important'}}
                                setErrors={setError}
                                accept={['application/pdf']} 
                              />}
            title={<Box color='danger.clr-700'>Upload failed!</Box>}
            message={
                      <Box className={classes.errorImage}>
                        <Typography variant='h6-regular' color='nuetral.clr-900'>
                          {errorText}
                        </Typography>
                      </Box>}
            secondaryAction="Back"
            secondaryActionType="outlined" 
            buttonSize='medium'
            onSecondaryAction={() => {
              setError(null);
            }}
          />
        </Box>
      </Box>
    );
};
    

const SelectDocumentOption = ({
  setManualCompanyName , 
  setOpenReportBasedInterview,
  setOpenSelectDocumentOptions,
  setActiveStep
}) => {
  const classes = useStyles();

  const setSelectedDocument =  useStore(store => store.setSelectedDocument);
  const init = useStore(store => store.init);

  useEffect(()=>{
    setManualCompanyName(false)
  },[])

  const selectDocument = (id) => {

    nextStepAudio();

    if(id === DocumentOptions.OTHERS._id || id === DocumentOptions.DAF._id){
      setOpenSelectDocumentOptions(false)
      setOpenReportBasedInterview(true)
      setSelectedDocument(id)
    }
    else { 
      init({})
      setOpenReportBasedInterview(false)
      setSelectedDocument(id)
      setActiveStep(id)
      setOpenSelectDocumentOptions(false)
    }
  }

  return (
    <Box className={classes.parentContainer}>
        <Box className={classes.heading}>
          <Typography variant='h5-medium' color='neutral.clr-900'>
            How would you like to prepare?
          </Typography>
        </Box>
        <Box className={classes.selectDocumentBox}>
          {Object.values(DocumentOptions).map((data,index) => (
            <Box 
              key={index} 
              className={classes.selectDocument} 
              sx={{backgroundColor : data.backgroundColor}}
              onClick={() => selectDocument(data._id)}
            >
              <Box minWidth='116px' display='flex' justifyContent='center' position='relative'>
                {data._id === DocumentOptions.OTHERS._id 
                  ? (
                    <Box className={classes.optionImageBoxReport}>
                      <img 
                        width={105} 
                        height={112}
                        src={data.img}
                        alt={data.label} 
                        className={classes.optionImageReport}
                      />
                    </Box>
                    ) 
                  : (
                      <Box className={classes.optionImageBox}>
                        <img 
                          width={92} 
                          height={112}
                          src={data.img}
                          alt={data.label} 
                          className={classes.optionImage}
                        />
                      </Box>
                   )}
              </Box>
              <Box className={classes.contentContainer}>
                {data.mostPreferred && (
                  <Chip
                    content={`Most preferred`}
                    bgColor={theme.palette.tertiary['clr-500']}
                    color={theme.palette.shades['clr-white-900']}
                    typographyVariant='overline-bold'
                    sx={{
                      borderRadius: '4px',
                      padding : '4px'
                    }}
                  />
                )}
                {data.new && (
                  <img 
                    src='https://assets.languify.in/images/new_chip.svg' 
                    alt='new' 
                    width={53}
                    height={17}
                  />
                )}
              
                <Typography
                  variant='body01-bold'
                  className={`${classes.radioSelectLabel}`}
                >
                    {data.label}
                </Typography>
                <Typography variant='overline-bold' color='neutral.clr-400'>
                  {data.subLabel}
                </Typography>
                {data.types && (
                  data.types.map((type , index)=> (
                    <Typography variant='overline-bold' color='neutral.clr-500'>• {type}</Typography>
                  )) 
                )}
              </Box>
            </Box>
          ))}
        </Box>                 
      </Box>
  )
}

const CreateInterview = () => {
  const classes = useStyles();
  const service = useServices();
  const navigateWithClient = useNavigateWithClient();
  const snackbar = useSnackbar();
  const { id } = useParams()

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [interviewError , setInterviewError] = useState(false)
  const [manualCompanyName , setManualCompanyName] = useState(false)
  const [clientMock , setClientMock] = useState(false)
  const [isNoResume , setIsNoResume] = useState(false)
  const [interviewRound , setInterviewRound] = useState('')
  const [interviewer , setInterviewer] = useState('')
  const [openSelectDocumentOptions , setOpenSelectDocumentOptions] = useState(true)
  const [stepNumber , setStepNumber] = useState(0)
  const [assessmentDialogOpen , setAssessmentDialogOpen] = useState(false)
  const [ isResponseReceived , setIsResponseReceived] = useState(false)
  const [template , setTemplate] = useState()
  const [openReportBasedInterview , setOpenReportBasedInterview] = useState(false)

  const [
          activeStep,companyName,
          jobRole,jobDescription,domain,selectedResumeDetails,
          selectedResumeId,templateDetails,draft,interviewName,
          setActiveStep,setSelectedDocument , selectedDocument , 
        ] = useStore(store => [
              store.activeStep,store.companyName,
              store.jobRole,store.jobDescription,store.domain,store.selectedResumeDetails,
              store.selectedResumeId,store.templateDetails,store.draft,store.interviewName,
              store.setActiveStep,store.setSelectedDocument , store.selectedDocument,
            ])

  const init = useStore(store => store.init);
            
  useEffect(()=>{
    if(id !=='create'){

      setOpenSelectDocumentOptions(false)
      setManualCompanyName(true)
      setSelectedDocument(DocumentOptions.JD_RESUME._id)
      setActiveStep(InterviewSteps.SELECT_RESUME._id)
      setStepNumber(1)
      setClientMock(true)

      const fetchDraft = async () => {
        try {
          const response = await service.callService(getDraftById , id);
          const fetchedDraft = response?.data?.INTERVIEW_DETAILS
          init({
            companyName : fetchedDraft.company,
            jobRole : fetchedDraft.jobRole,
            domain : fetchedDraft.domain,
            jobDescription : fetchedDraft.jobDescription,
            templateDetails : {
              batch :fetchedDraft.batch,
              publishOn :fetchedDraft.publishOn,
              expiresOn:fetchedDraft.expiresOn,
              proctorSettings:{...fetchedDraft.proctorSettings}
            },
            draft : response?._id,
            interviewName : fetchedDraft.name
          })
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchDraft();
    }
  },[])

  const handleInterviewStep = () => {
    setOpenSelectDocumentOptions(false)
    init( activeStep,
      companyName,
      jobRole,
      jobDescription,
      domain,
      selectedResumeDetails)
}

const handleBeginInterview = async () => {
  try {
    setInterviewError(false)

    nextStepAudio()

    setAssessmentDialogOpen(true)
    const dataToSend = {};
    dataToSend.personalizedTemplateType = selectedDocument === DocumentOptions.JD_RESUME._id 
                                            ? PersonalizedTemplateType.jd_resume
                                            : selectedDocument === DocumentOptions.JD._id 
                                              ? PersonalizedTemplateType.jobDescription
                                              : PersonalizedTemplateType.resume

    dataToSend.domain = domain._id || domain;

    if(companyName){
      dataToSend.company = companyName.name || companyName;
    }

    if(jobRole){
      dataToSend.jobRole = jobRole.name || jobRole;
    }

    if (jobDescription) {
      dataToSend.jobDescription = jobDescription;
    }

    if(draft){
      dataToSend.draft = draft
    }

    if (selectedResumeId) {
      dataToSend.resume = selectedResumeId;
    }

    if(templateDetails){
      dataToSend.templateDetails = {
        name: interviewName,
        batch : templateDetails.batch,
        publishOn : templateDetails.publishOn,
        expiresOn: templateDetails.expiresOn,
        proctorSettings: templateDetails.proctorSettings
      }
    }

    dataToSend.templateDetails = {
      name: interviewName,
      interviewRound,
      interviewer 
    };
    
    const response = await service.callService(createPersonalizedTemplate, { ...dataToSend });
    
    if(response){
      setIsResponseReceived(true)
      setTemplate(response.interviewTemplate)
    }
  } catch (err) {
    console.error("Error while creating personalized template:", err.message);
    snackbar.error(err.message);
    setInterviewError(true)
  }
};

  return (
    <AppShell headerLabel='Interview Wizard'>
      <Box className={classes.createContainer}>
        {openSelectDocumentOptions 
          ? 
            (
              <SelectDocumentOption 
                handleInterviewStep={handleInterviewStep}  
                setManualCompanyName={setManualCompanyName}
                setOpenReportBasedInterview={setOpenReportBasedInterview}
                setOpenSelectDocumentOptions={setOpenSelectDocumentOptions}
                setActiveStep={setActiveStep}
              />
            ) 
          : openReportBasedInterview 
          ? (
              <ReportBasedInterview
                setOpenSelectDocumentOptions={setOpenSelectDocumentOptions} 
                setOpenReportBasedInterview={setOpenReportBasedInterview}
                setStepNumber={setStepNumber}
                stepNumber={stepNumber}
              />
            )
          :  activeStep
          ?
            (
              <Box 
                className={classes.parentContainer}
              >
                <Steps 
                  stepNumber={stepNumber} 
                  setStepNumber={setStepNumber} 
                  setOpenSelectDocumentOptions={setOpenSelectDocumentOptions}
                />

                {activeStep === InterviewSteps.JOB_DETAILS._id && 
                  (
                    <SelectJobDetails 
                      stepNumber={stepNumber} 
                      next={setStepNumber} 
                      manualCompanyName={manualCompanyName}
                      setManualCompanyName={setManualCompanyName}
                      clientMock={clientMock}
                    />
                  )}
                {activeStep === InterviewSteps.SELECT_INTERVIEW_ROUND._id &&
                (
                  <InterviewRound 
                    stepNumber={stepNumber}
                    next={setStepNumber}
                    setInterviewRound={setInterviewRound}
                  />
                )}
                {activeStep === InterviewSteps.SELECT_INTERVIEWER._id &&
                (
                  <SelectInterviewer
                    stepNumber={stepNumber}
                    next={setStepNumber}
                    setInterviewer={setInterviewer}
                  />
                )}
                {activeStep === InterviewSteps.SELECT_RESUME._id && 
                  (
                    <SelectResume 
                      stepNumber={stepNumber} 
                      next={setStepNumber} 
                      setIsNoResume={setIsNoResume}
                      clientMock={clientMock}
                    />
                  )}
                {activeStep === InterviewSteps.PREVIEW._id && 
                  (
                    <Preview 
                      noResume={isNoResume} 
                      beginInterview={handleBeginInterview} 
                      template={template} 
                      isResponseReceived={isResponseReceived} 
                      isDialogOpen={assessmentDialogOpen} 
                      setIsDialogOpen={setAssessmentDialogOpen}
                      clientMock={clientMock}
                      error={interviewError}
                      interviewer={interviewer}
                      interviewRound={interviewRound}
                    />
                  )} 
              </Box>
            )
            : null}     
      </Box>
    </AppShell>
  );
};

export default CreateInterview;
