import React from "react";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import synthesizeSpeech from "utils/synthesizeSpeech";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Skeleton from "@mui/material/Skeleton";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import useStore from "./store";
import { Interviewers } from "components/Assessment/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  header: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    position: 'relative',
    zIndex: 0
  },
  tooltip: {
    height: '130px',
    width: '320px',
    boxShadow: '0px 0px 12px 2px #1961BF80',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 10,
    top: '0',
    right: '-350px',
    background: 'white',
  },
  tooltipHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  }, 
  endFeedback: {
    textTransform: 'none',
    textDecoration: 'underline',
    color: theme.palette.primary['clr-300'],
    ...theme.typography['body02-bold'],
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  divider: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.neutral['clr-200']
  },
  pointer: {
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '20px solid',
    borderRightColor: theme.palette.primary.main,
    position: 'absolute',
    top: '10px',
    left: '-20px',
  }, textContainer: {
    padding: theme.spacing(3),
    height: 'calc(100% - 60px)',
    overflowY: 'scroll',
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  },
  '@keyframes autoscroll': {
    from: {
      transform: 'translateY(0%)',
    },
    to: {
      transform: 'translateY(-100%)',
    }
  }, audioText: {
    height: '100%',
    ...theme.typography['body02-bold'],
    color: theme.palette.neutral['clr-900'],
    transform: 'translateY(0%)',
    // animationName: '$autoscroll',
    animationDuration: '10s',
    animationIterationCount: 'initial',
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
  }, infoContainer: {
    height: '80px',
    width: '320px',
    backgroundColor: theme.palette.primary['clr-500'],
    borderRadius: theme.spacing(2),
    padding: '20px',
    position: 'absolute',
    right: '-100%',
    top: 0,
  },
  skeletonWrapper: {
    width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center'
  },
  muteButton: {
    border: '1px solid',
    borderColor: theme.palette.primary['clr-100'],
    backgroundColor: theme.palette.primary['clr-50'],
    padding: '2px'
  }
}));

function FeedbackAudioTooltip(
  { audioText, hide = true, endFeedback = () => { }, playing, handleMute=()=>{}, isMuted, interviewerDetails }
) {
  const classes = useStyles();
  const scrollContainerRef = React.useRef(null);

  React.useEffect(() => {
    let blinkInterval = null;
    let scrollInterval = null;

    if (playing) {

      if (scrollContainerRef.current) {

        scrollInterval = setInterval(() => {
          scrollContainerRef?.current?.scrollBy(0, 1);
          if (
            scrollContainerRef?.current?.scrollTop +
            scrollContainerRef?.current?.clientHeight >=
            scrollContainerRef?.current?.scrollHeight
          ) {
            clearInterval(scrollInterval);
            scrollInterval = null;
          }
        }, 150);
      }

    }
    return () => {
      if (scrollInterval) clearInterval(scrollInterval);
      if (blinkInterval) {
        clearInterval(blinkInterval);
      };
    }
  }, [playing]);

  return (
    hide ? null : <Box className={classes.tooltip}>

      <Box className={classes.pointer}></Box>

      <Box className={classes.tooltipHeader}>
        <Box display='flex' alignItems='center' gap={2}>
          <img
            src={interviewerDetails.image}
            alt="Interviewer"
            width="36px"
            height="36px"
          />

          <Typography variant="body02-bold" color='success.clr-700'>
            {interviewerDetails.name} is speaking
          </Typography>
          <IconButton onClick={handleMute} className={classes.muteButton}>
            {
              isMuted ? 
              <VolumeOffOutlinedIcon color='primary' fontSize='small'/> :
              <VolumeUpOutlinedIcon color='primary' fontSize='small'/>
            }
          </IconButton>
        </Box>
        <IconButton onClick={endFeedback}>
          <CloseOutlinedIcon/>
        </IconButton>
      </Box>

      <div className={classes.divider}></div>

      <Box className={classes.textContainer} ref={scrollContainerRef}>
        <Typography className={classes.audioText} >
          {audioText}
        </Typography>
      </Box>
    </Box>
  );
}

function AudioInfoTooltip({ hide = false }) {
  const classes = useStyles();

  return hide ? null : <Box className={classes.infoContainer}>
    <Box className={classes.pointer}></Box>
    <Typography variant="body02-semiBold" color='shades.clr-white-900'>
      Know what went well & what can be improved by your interviewer.
    </Typography>
  </Box>;
}

export default function FeedbackAudio({ audioText = '', loading = false }) {
  const classes = useStyles();
  const audioRef = React.useRef(null);
  const [playInitiated, setPlayInitiated] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [paused, setPaused] = React.useState(false);
  const [muted, setmuted] = React.useState(false);
  const [imgLoading, setImgLoading] = React.useState(true);
  const template = useStore(store => store.template);
  const [interviewerDetails, setInterviewerDetails] = React.useState(Interviewers.Niamh);

  React.useEffect(()=>{
    setInterviewerDetails(Interviewers[template?.metadata?.interviewer || 'Niamh']);
  },[template]);

  React.useEffect(() => (async () => {
    const input = {
      engine: 'neural', languageCode: 'en-GB', voiceId: 'Amy', text: audioText
    };
    const { audioUrl } = await synthesizeSpeech(input);
    if(audioRef.current){
      audioRef.current.src = audioUrl;
      audioRef.current.addEventListener('pause', () => setPlaying(false));
      audioRef.current.addEventListener('play', () => setPlaying(true));
      console.log('AUDIO:DEBUG: successfully configured ');
    }
  })(), [audioText]);

  const play = React.useCallback(() => {
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.play();
      console.log('AUDIO:DEBUG: playing...');
    }
    if(audioRef.current && audioRef.current.muted){
      setmuted(false);
      audioRef.current.muted = false;
    }
    setPaused(false);
    setPlayInitiated(true);
  }, []);

  const pause = React.useCallback(() => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause();
      setPaused(true);
      console.log('AUDIO:DEBUG: pause');
    }
  }, []);

  const handleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
      setmuted(!muted);
    }
  };

  const reset = React.useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      console.log('AUDIO:DEBUG: reset');
    }
    setPaused(false);
    setmuted(false)
    setPlayInitiated(false);
  }, []);

  const handleImgLoad = (value) => setImgLoading(false)

  React.useEffect(() => {
    if (loading) reset();
  }, [loading]);

  return (

    <Box className={classes.root}>

      {
        (imgLoading) && <Skeleton variant="circular" width={64} height={64} />
      }

      <img
        src={interviewerDetails.image}
        alt="Interviewer"
        width={64}
        style={{ display: imgLoading ? 'none' : 'block' }}
        onLoad={handleImgLoad}
      />

      <audio ref={audioRef}></audio>

      <Box className={classes.header}>
        <Box display='flex' alignItems='center' gap={2}>
          <Typography variant="h4-medium" color='neutral.clr-900'>
            Interviewer’s perspective
          </Typography>

          {
            loading ?
              <Box className={classes.skeletonWrapper}>
                <Skeleton variant='circular' height={28} width={28} animation='wave' />
              </Box> :
              <IconButton onClick={playing ? pause : play} >
                {
                  playInitiated && paused ? 
                    <PauseCircleOutlineOutlinedIcon color="primary" fontSize="medium"/> :
                    <PlayCircleIcon color="primary" fontSize="medium" />
                }
              </IconButton>
          }

          <AudioInfoTooltip
            hide={playInitiated}
          />
          <FeedbackAudioTooltip
            audioText={audioText}
            hide={!playInitiated}
            endFeedback={reset}
            isMuted={muted}
            handleMute={handleMute}
            playing={playing}
            audioDuration={audioRef.current?.duration ?? 0}
            interviewerDetails={interviewerDetails}
          />
        </Box>
        <Typography variant="h6-medium" color='neutral.clr-600'>
          Interviewer’s feedback for your interview
        </Typography>
      </Box>

    </Box>
  );
}