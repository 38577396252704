import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const themeOptions = {
  palette: {
    type: 'light',
    neutral: {
      'clr-50': '#F5F9FF',
      'clr-100': '#E9EDF2',
      'clr-200': '#D3D8DE',
      'clr-300': '#A7B1BE',
      'clr-400': '#8692A3',
      'clr-500': '#636F7F',
      main: '#636F7F',
      'clr-600': '#4E5661',
      'clr-700': '#3C434D',
      'clr-800': '#262B30',
      'clr-900': '#111519',
      disabled: '#E1E3E5'
    },
    primary: {
      'clr-50': '#E5F1FF',
      'clr-100': '#9DC2F2',
      'clr-200': '#4C89D9',
      'clr-300': '#135EBF',
      'clr-400': '#1151A6',
      'clr-500': '#003E8C',
      dark: '#002759',
      main: '#003E8C',
      'clr-600': '#003273',
      'clr-700': '#002759',
      'clr-800': '#001C40',
      'clr-900': '#001126',
    },
    secondary: {
      'clr-50': '#F0FDFF',
      'clr-100': '#DEF7FC',
      'clr-200': '#D2F3FA',
      'clr-300': '#C3F0FA',
      'clr-400': '#B1EDFA',
      'clr-500': '#9DE4F2',
      main: '#9DE4F2',
      'clr-600': '#89D6E5',
      'clr-700': '#52B8CC',
      'clr-800': '#2D9BB2',
      'clr-900': '#0F8199',
    },
    tertiary: {
      'clr-50': '#F4F0FF',
      'clr-100': '#DFD8FD',
      'clr-200': '#B8ACF6',
      'clr-300': '#9F8FEF',
      'clr-400': '#8F7EE7',
      'clr-500': '#8270DB',
      main: '#8270DB',
      'clr-600': '#6E5DC6',
      'clr-700': '#5E4DB2',
      'clr-800': '#362C63',
      'clr-900': '#2B273F',
    },
    warning: {
      'clr-100': '#FFF6E5',
      'clr-300': '#F5B847',
      'clr-500': '#BF7900',
      main: '#BF7900',
      'clr-700': '#664100',
    },
    success: {
      'clr-100': '#DCFCE7',
      'clr-300': '#2BD9A9',
      'clr-500': '#009970',
      main: '#009970',
      'clr-700': '#00664A',
    },
    danger: {
      'clr-100': '#FFE6E3',
      'clr-300': '#FD9891',
      'clr-500': '#E2483C',
      main: '#E2483C',
      'clr-700': '#C51407',
    },
    shades: {
      'clr-white-900': '#FFFFFF',
      'clr-black-900': '#000000',
      'clr-offWhite': '#FAFCFC',
      'clr-grey-100': '#EDF1F7',
    },
    default: {
      main: '#ffffff',
    },
    divider: '#8692A3',
  },

  typography: {
    'h1-xl-regular': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 400,
      fontSize: '57px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '36px',
        lineHeight: '122%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '25px',
        lineHeight: '128%',
      },
    },
    'h1-xl-medium': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 500,
      fontSize: '57px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '36px',
        lineHeight: '122%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '25px',
        lineHeight: '128%',
      },
    },
    'h1-xl-semiBold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 600,
      fontSize: '57px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '36px',
        lineHeight: '122%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '25px',
        lineHeight: '128%',
      },
    },
    'h1-xl-bold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 700,
      fontSize: '57px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '36px',
        lineHeight: '122%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '25px',
        lineHeight: '128%',
      },
    },
    'h1-regular': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 400,
      fontSize: '48px',
      lineHeight: '124%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '32px',
        lineHeight: '136%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '132%',
      },
    },
    'h1-medium': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 500,
      fontSize: '48px',
      lineHeight: '124%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '32px',
        lineHeight: '136%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '132%',
      },
    },
    'h1-semiBold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 600,
      fontSize: '48px',
      lineHeight: '124%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '32px',
        lineHeight: '136%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '132%',
      },
    },
    'h1-bold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 700,
      fontSize: '48px',
      lineHeight: '124%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '32px',
        lineHeight: '136%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '132%',
      },
    },
    'h2-regular': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 400,
      fontSize: '40px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '29px',
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '22px',
        lineHeight: '128%',
      },
    },
    'h2-medium': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 500,
      fontSize: '40px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '29px',
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '22px',
        lineHeight: '128%',
      },
    },
    'h2-semiBold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 600,
      fontSize: '40px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '29px',
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '22px',
        lineHeight: '128%',
      },
    },
    'h2-bold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '29px',
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '22px',
        lineHeight: '128%',
      },
    },
    'h3-regular': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 400,
      fontSize: '33px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '26px',
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '21px',
        lineHeight: '132%',
      },
    },
    'h3-medium': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 500,
      fontSize: '33px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '26px',
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '21px',
        lineHeight: '132%',
      },
    },
    'h3-semiBold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 600,
      fontSize: '33px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '26px',
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '21px',
        lineHeight: '132%',
      },
    },
    'h3-bold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 700,
      fontSize: '33px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '26px',
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '21px',
        lineHeight: '132%',
      },
    },
    'h4-regular': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 400,
      fontSize: '28px',
      lineHeight: '128%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '23px',
        lineHeight: '120%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '19px',
        lineHeight: '124%',
      },
    },
    'h4-medium': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 500,
      fontSize: '28px',
      lineHeight: '128%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '23px',
        lineHeight: '120%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '19px',
        lineHeight: '124%',
      },
    },
    'h4-semiBold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '128%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '23px',
        lineHeight: '120%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '19px',
        lineHeight: '124%',
      },
    },
    'h4-bold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '128%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '23px',
        lineHeight: '120%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '19px',
        lineHeight: '124%',
      },
    },
    'h5-regular': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 400,
      fontSize: '23px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '132%',
      },
    },
    'h5-medium': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 500,
      fontSize: '23px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '132%',
      },
    },
    'h5-semiBold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 600,
      fontSize: '23px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '132%',
      },
    },
    'h5-bold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 700,
      fontSize: '23px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '132%',
      },
    },
    'h6-regular': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 400,
      fontSize: '19px',
      lineHeight: '124%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '132%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '17px',
        lineHeight: '120%',
      },
    },
    'h6-medium': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 500,
      fontSize: '19px',
      lineHeight: '124%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '132%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '17px',
        lineHeight: '120%',
      },
    },
    'h6-semiBold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 600,
      fontSize: '19px',
      lineHeight: '124%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '132%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '17px',
        lineHeight: '120%',
      },
    },
    'h6-bold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 700,
      fontSize: '19px',
      lineHeight: '124%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '132%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '17px',
        lineHeight: '120%',
      },
    },
    'body01-regular': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '128%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '124%',
      },
    },
    'body01-medium': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '128%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '124%',
      },
    },
    'body01-semiBold': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '128%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '124%',
      },
    },
    'body01-bold': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '128%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '124%',
      },
    },
    'body01-link': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '128%',
      letterSpacing: '0px',
      textDecoration: 'underline',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '124%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '124%',
      },
    },
    'body02-regular': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '140%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '132%',
      },
    },
    'body02-medium': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '140%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '132%',
      },
    },
    'body02-semiBold': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '140%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '132%',
      },
    },
    'body02-bold': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '120%',
      letterSpacing: '0px',

      [defaultTheme.breakpoints.down('md')]: {
        lineHeight: '140%',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '132%',
      },
    },
    'overline-medium': {
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '120%',
      letterSpacing: '1px',
      textTransform: 'uppercase',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '13px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '13px',
        textTransform: 'none'
      },
    },
    'overline-semiBold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 600,
      fontSize: '10px',
      lineHeight: '120%',
      letterSpacing: '1px',
      textTransform: 'uppercase',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '13px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '13px',
        textTransform: 'none'
      },
    },
    'overline-bold': {
      fontFamily: '"Roboto Flex", sans-serif',
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: '120%',
      letterSpacing: '1px',
      textTransform: 'uppercase',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '13px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '13px',
        textTransform: 'none'
      },
    },
  },

  spacing: ((...value) => {
    let str = '';
    for (let i = 0; i < value.length; i++) {
      str += `${4 * value[i]}px `;
    }

    return str;
  })
};

const theme = createTheme(themeOptions);

export default theme;