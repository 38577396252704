import React, { useEffect , useState } from 'react'
import { Box, Tooltip, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

import { AutoCompleteSelect, SimpleSelect } from 'components/CustomSelectFields';
import { DafInterviewSteps, DocumentOptions, Interviewer, PersonalizedTemplateType, ReportInterviewSteps, ReportOptions } from './constants';
import { createPersonalizedTemplate, getDomains } from 'services';
import { useServices, useSnackbar } from 'contexts';
import { nextStepAudio } from 'utils';
import CaseStudy from './CaseStudy';
import DafUpload from './DafUpload';
import DafTopicSelection from './DafTopicSelection';
import DafForm from './DafForm';
import useStore from './store';
import DafPreferenceSelection from './DafPreferenceSelection';
import Steps from './Steps';
import  CustomButton from 'components/CustomButton';
import SelectInterviewer from './SelectInterviewer';
import StartPersonalizedAssessmentDialog from 'dialogs/StartPersonalizedAssessmentDialog';

const useStyles = makeStyles((theme) => ({
  whiteBoxContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(7),
    alignItems:'center',
    padding:theme.spacing(6),
    backgroundColor : theme.palette.shades['clr-white-900'],
    borderRadius: '4px',
    position:'relative',
    maxWidth:'390px',
    width : '100%',
    minHeight:'608px',
    maxHeight : '608px',
    position : 'relative',
  },
  backArrow:{
    backgroundColor:theme.palette.shades['clr-white-900'] , 
    color:theme.palette.neutral.main,
    '&:hover': {
    backgroundColor: theme.palette.shades['clr-white-900'], 
  },
  },
  reportBasedContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(5),
    justifyContent:'center',
    alignItems:'center',
    marginTop : theme.spacing(13),
  },
  header:{
    display:'flex',
    gap:theme.spacing(1),
    height:'52px',
    gap:theme.spacing(13.5),
    alignItems:'center',
    borderBottom : `1px solid ${theme.palette.neutral['clr-200']}`
  },
  bottomContentContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(3.5)
  },
  scrollContainer:{
    display:'flex',
    flexDirection:'column',
    gap: theme.spacing(4),
    overflowY:'auto',
    width:'100%',
    alignItems:'center',
    maxHeight:'364px',
    height:'364px',
    animation: '$slideInFromRight 0.6s ease-out',
  },
  mockDetailsContainer:{
    overflowY:'auto',
    display:'flex',
    justifyContent:'center',
    flexDirection : 'column',
    gap : theme.spacing(5),
    marginTop : theme.spacing(13),
    animation: '$slideInFromRight 0.6s ease-out',
  },
  mockDetailsBox:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(3)
  },
  mockDetail:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(1),
  },
  bottomBox:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(2),
    justifyContent:'center',
    alignItems:'center',
    width:'100%',
    position : 'absolute',
    bottom : '30px',
    left : '0px'
  },
    tooltip: {
      backgroundColor: theme.palette.shades['clr-white-900'],
      color: theme.palette.neutral['clr-900'], 
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', 
      borderRadius: '4px',
      padding: theme.spacing(1,2),
      ...theme.typography['body02-bold'],
      minWidth:'336px',
      height:'45px',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      textAlign:'center',
      border:`1px solid ${theme.palette.neutral['clr-500']}`
    },
    arrow: {
      color: theme.palette.shades['clr-white-900'],
      '&::before': {
        border:`1px solid ${theme.palette.neutral['clr-500']}`,  
        backgroundColor: theme.palette.shades['clr-white-900'],   
        boxSizing: 'border-box', 
      },
    },
    '@keyframes slideInFromRight': {
      '0%': {
        transform: 'translateX(15px)',
        opacity: 0
      },
      '100%': {
        transform: 'translateX(0)',
        opacity: 1
      }
    },
}))


const ReportBasedInterview = ({
  setOpenSelectDocumentOptions,
  setStepNumber,
  stepNumber
}) => {
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar()

  const [activeStep] = useStore(store => [store.activeStep])

  const [ reportOption , setReportOption ] = useState('')
  const [domains, setDomains] = useState();
  const [selectedDomain , setSelectedDomain] = useState('')
  const [fileName , setFileName] = useState('')
  const [assessmentDialogOpen , setAssessmentDialogOpen] = useState(false)
  const [isResponseReceived , setIsResponseReceived] = useState(false)
  const [interviewError , setInterviewError] = useState(false)
  const [template , setTemplate] = useState()
  const [finalReport , setFinalReport] = useState('')
  const [interviewer , setInterviewer] = useState('')

  useEffect(() => {
    const fetchDomains = async () => {
      try {            
        const domains = await service.callService(getDomains);
        setDomains(domains.interviewDomains.sort((a, b) => a.name.localeCompare(b.name)));
        
      } catch (err) {
        console.error(err.message);
      } 
    };

    fetchDomains();
  }, []);

  const handleBeginInterview = async () => {
    try {
      setInterviewError(false)
      setAssessmentDialogOpen(true)
  
      const response = await service.callService(createPersonalizedTemplate, {  
        domain : selectedDomain._id,
        personalizedTemplateType : PersonalizedTemplateType.report,
        report: finalReport,
        templateDetails : {
          interviewer : interviewer,
        }
       });
      
      if(response){
        setIsResponseReceived(true)
        setTemplate(response.interviewTemplate)
      }
    } catch (err) {
      console.error("Error while creating personalized template:", err.message);
      snackbar.error(err.message);
      setInterviewError(true)
    }
  };

  return (
    <Box className={classes.whiteBoxContainer}>

          <Steps
            stepNumber={stepNumber} 
            setStepNumber={setStepNumber} 
            setOpenSelectDocumentOptions={setOpenSelectDocumentOptions}
          />
          {activeStep === DafInterviewSteps.DAF_UPLOAD._id && (
            <DafUpload   
              stepNumber={stepNumber} 
              setStepNumber={setStepNumber} 
          />)}  

          {activeStep === DafInterviewSteps.FORM_PREVIEW._id  && 
            <DafForm
              stepNumber={stepNumber} 
              setStepNumber={setStepNumber} 
          />}

          {activeStep === DafInterviewSteps.SELECT_PREFERENCE._id && <DafPreferenceSelection stepNumber={stepNumber} setStepNumber={setStepNumber} />}

          {activeStep === DafInterviewSteps.SELECT_DURATION_TOPIC._id && <DafTopicSelection stepNumber={stepNumber} setStepNumber={setStepNumber}/>} 

          {activeStep === ReportInterviewSteps.SELECT_REPORT._id && (
            <Box className={classes.reportBasedContainer}>
              <Typography variant='h5-medium' color='neutral.clr-600'>
                Let’s, Prepare questions that Interviewer might ask you!
              </Typography>
              <Box className={classes.scrollContainer}>
                <SimpleSelect
                    value={reportOption}
                    options={ReportOptions}
                    label='Report type'
                    sx={{width: '336px', height:'48px'}}
                    menuStyle= {{width: '336px'}}
                    width='484px'
                    onSelect={(data)=> setReportOption(data)}
                  />
                  <AutoCompleteSelect
                    options={domains || []}
                    value={selectedDomain}
                    label='Domain'
                    style={{ width: '336px' , height:'48px'}}
                    placeholder='Select Domain'
                    onSelect={(data)=>setSelectedDomain(data)}
                    menuStyle={{maxHeight: '200px'}}
                    width='336px'
                  />
                  
              </Box>   
              <CustomButton
                    sx={{
                      position : 'absolute',
                      bottom : '30px',
                      width : '336px'
                    }}
                    size='large'
                    disabled={!reportOption || !selectedDomain}
                    onClick={() => {
                      setStepNumber(stepNumber + 1)
                      nextStepAudio();
                    }}
                  >
                    Next
                  </CustomButton>  
            </Box>
          )}
          
          {activeStep === ReportInterviewSteps.UPLOAD_CONTENT._id && (
            <CaseStudy 
              stepNumber={stepNumber} 
              setStepNumber={setStepNumber} 
              fileName={fileName}
              setFileName={setFileName}
              setFinalReport={setFinalReport}
            />
          )}

          {activeStep === ReportInterviewSteps.SELECT_INTERVIEWER._id && (
            <SelectInterviewer
              stepNumber={stepNumber} 
              next={setStepNumber}  
              setInterviewer={setInterviewer}
            />
          )}

          {activeStep === ReportInterviewSteps.PREVIEW._id && (
            <Box>
              <Box className={classes.mockDetailsContainer}>
                <Typography variant='h5-medium' color='neutral.clr-900'>
                  Preview interview details before you begin...
                </Typography>
                <Box sx={{ display:'flex', flexDirection:'column', gap:'12px'}}>
                  <Box className={classes.mockDetailsBox}>
                    <Box className={classes.mockDetail}>
                      <Typography 
                        variant='body01-bold' 
                        color='neutral.clr-600' 
                        >
                          Report type
                      </Typography>
                      <Typography variant='body01-bold'>
                          {reportOption.name}
                      </Typography>
                    </Box>
                  </Box>
                  {fileName && (
                    <Box className={classes.mockDetailsBox}>
                      <Box className={classes.mockDetail}>
                        <Typography 
                          variant='body01-bold' 
                          color='neutral.clr-600' 
                          >
                            Report name
                        </Typography>
                        <Typography variant='body01-bold'>
                            {fileName}
                        </Typography>
                      </Box>
                    </Box>
                  )}     
                  <Box className={classes.mockDetailsBox}>
                    <Box className={classes.mockDetail}>
                      <Typography 
                        variant='body01-bold' 
                        color='neutral.clr-600' 
                        >
                          Interviewer name
                      </Typography>
                      <Typography variant='body01-bold'>
                        {Interviewer.find(interview => interview.voiceId === interviewer)?.name || ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
                <Box className={classes.bottomBox}>
                <Tooltip 
                  title={`Creating a new interview will use 1 credit from your available credits`}
                  arrow 
                  placement="top"
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'flip',
                        enabled: false, 
                      },
                    ],
                  }}
                  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                >
                  <Box>
                    <CustomButton 
                        variant='contained' 
                        size='medium'
                        sx={{width:'336px'}}
                        onClick={handleBeginInterview}
                      >
                        Begin Interview
                      </CustomButton>
                  </Box>
                </Tooltip>
              </Box> 
                <StartPersonalizedAssessmentDialog 
                  openDialog={assessmentDialogOpen} 
                  onClose={()=>  setAssessmentDialogOpen(false)} 
                  responseReceived={isResponseReceived}
                  template={template}
                  error={interviewError}
                />
              </Box>
            )}
    </Box>
  )
}

export default ReportBasedInterview