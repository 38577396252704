import Client from "./_client";
import Session from "../utils/Session";

export const saveInterviewAttempt = async (attemptId, data) => {
  let result;
  if (attemptId) {
    result = await new Client({
      path: `/interviewAttempt/${attemptId}`,
      payload: {
        interviewTemplate: data.interviewTemplate,
        totalQuestions: data.totalQuestions,
        user: Session.userId,
        experienceLevel: "INTERNSHIP",
        finishedAt: Date.now(),
      },
    }).put();
  } else {
    const payload = {
      totalQuestions: data.totalQuestions,
      interviewTemplate: data.interviewTemplate,
      user: Session.userId,
      experienceLevel: "INTERNSHIP",
      startedAt: Date.now(),
      batch: data.batch
    };

    if (data.pathway) payload['pathway'] = data.pathway;

    result = await new Client({
      path: "/interviewAttempt",
      payload: payload
    }).post();
  }

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! save the interview attempt. Please try again"
    );
  }

  return result?.data;
};

export const finishInterviewAttempt = async (attemptId, timeout, proctoringRecords) => {
  if (!attemptId) return;

  const result = await new Client({
    path: `/interviewAttempt/${attemptId}/finish`,
    payload: {
      finishedAt: Date.now(),
      timeout,
      proctoringRecords
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! save the interview attempt. Please try again"
    );
  }
  return result?.data;
};

export const getInterviewAttempts = async (filters) => {
  let url = "/interviewAttempt?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! save the interview attempt. Please try again"
    );
  }

  return result?.data;
};

export const getPersonalizedInterviewAttempts = async (filters) => {
  let url = "/interviewAttempt/personalized?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! didn't get interview attempt. Please try again"
    );
  }

  return result?.data;
};

export const getOverallPercentile = async () => {
  const url = `/interviewAttempt/percentile/${Session.userId}`
  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get first attempt. Please try again"
    );
  }

  return result?.data;
};

export const getParameterScores = async (filters) => {
  let url = "/interviewAnswer/parameter-scores?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Please try again"
    );
  }

  return result?.data;
};

export const getGamificationScores = async (filters) => {
  let url = "/interviewAnswer/gamification-scores?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Please try again"
    );
  }

  return result?.data;
};